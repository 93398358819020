/* included only when we don't want to use fonts from google server */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(/assets/fonts/OpenSans-300.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(/assets/fonts/OpenSans-400.woff) format('woff');
}

@font-face {
	font-family: 'proxima-nova-thin';
	src: url('/assets/fonts/ProximaNovaThin.eot');
	src: local('ProximaNovaThin'), url('/assets/fonts/ProximaNovaThin.woff') format('woff'), url('/assets/fonts/ProximaNovaThin.ttf') format('truetype');
}

@font-face {
	font-family: 'proxima-nova-light';
	src: url('/assets/fonts/ProximaNovaLight.eot');
	src: local('ProximaNovaLight'), url('/assets/fonts/ProximaNovaLight.woff') format('woff'), url('/assets/fonts/ProximaNovaLight.ttf') format('truetype');
}

@font-face {
	font-family: 'proxima-nova-regular';
	src: url('/assets/fonts/ProximaNovaRegular.eot');
	src: local('ProximaNovaRegular'), url('/assets/fonts/ProximaNovaRegular.woff') format('woff'), url('/assets/fonts/ProximaNovaRegular.ttf') format('truetype');
}
