.timeline-style2 .timeline-info {
  width: 100px;
}
.timeline-style2 .timeline-indicator {
  font-size: 0;
  height: 12px;
  line-height: 12px;
  width: 12px;
  border-width: 1px !important;
  background-color: #FFFFFF !important;
  position: absolute;
  left: 85px;
  top: 3px;
  opacity: 1;
  border-radius: 100%;
  display: inline-block;
  padding: 0;
}
.timeline-style2 .timeline-date {
  display: inline-block;
  width: 72px;
  text-align: right;
  margin-right: 25px;
  color: #777;
}
.timeline-style2 .timeline-item .widget-box {
  margin-left: 112px;
}
.timeline-style2 .timeline-label {
  width: 75px;
  text-align: center;
  margin-left: 0;
  margin-bottom: 10px;
  text-align: right;
  color: #666;
  font-size: 14px;
}
.timeline-time {
  text-align: center;
  position: static;
}
.dataTables_length select {
  width: 70px;
  height: 25px;
  padding: 2px 3px;
}
.dataTables_length label {
  font-weight: normal;
}
.dataTables_filter {
  text-align: right;
}
.dataTables_filter input[type=text],
.dataTables_filter input[type=search] {
  width: 125px;
  height: 18px;
  line-height: 18px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  padding: 4px 6px;
}
.dataTables_filter label {
  font-weight: normal;
}
.dataTables_info {
  font-size: 14px;
}
.dataTables_paginate {
  text-align: right;
}
.dataTables_paginate .pagination {
  margin: 0 12px;
}
.dataTables_wrapper label {
  display: inline-block;
  font-size: 13px;
}
.dataTables_wrapper input[type=text],
.dataTables_wrapper input[type=search],
.dataTables_wrapper select {
  margin-bottom: 0 !important;
  margin: 0 4px;
}
.dataTables_wrapper .row {
  margin: 0 !important;
}
.dataTables_wrapper .row:first-child {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #EFF3F8;
}
.dataTables_wrapper .row:first-child + .dataTable {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.dataTables_wrapper .row:last-child {
  border-bottom: 1px solid #e0e0e0;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #EFF3F8;
}
.dataTables_wrapper .dataTables_scroll + .row {
  border-top: 1px solid #e0e0e0;
}
.dataTable {
  margin-bottom: 0;
}
.dataTable > thead > tr > th[class*=sort] {
  cursor: pointer;
}
.dataTable > thead > tr > th[class*=sort]:after {
  float: right;
  display: inline;
  content: "\f0dc";
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: normal;
  color: #555;
}
.dataTable > thead > tr > th[class*=sort]:hover {
  color: #547ea8;
}
.dataTable > thead > tr > th[class*=sorting_] {
  color: #307ecc;
}
.dataTable > thead > tr > th.sorting_desc,
.dataTable > thead > tr > th.sorting_asc {
  background-image: -webkit-linear-gradient(top, #eff3f8 0%, #e3e7ed 100%);
  background-image: -o-linear-gradient(top, #eff3f8 0%, #e3e7ed 100%);
  background-image: linear-gradient(to bottom, #eff3f8 0%, #e3e7ed 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeff3f8', endColorstr='#ffe3e7ed', GradientType=0);
}
.dataTable > thead > tr > th.sorting_desc:after {
  content: "\f0dd";
  top: -6px;
  color: #307ecc;
}
.dataTable > thead > tr > th.sorting_asc:after {
  content: "\f0de";
  top: 4px;
  color: #307ecc;
}
.dataTable > thead > tr > th.sorting_disabled {
  cursor: inherit;
}
.dataTable > thead > tr > th.sorting_disabled:after {
  display: none;
}
.dataTables_scrollHead + .dataTables_scrollBody > .dataTable > thead > tr > th:after {
  display: none;
}
.dataTables_scrollHeadInner {
  width: auto !important;
}
.dataTables_scrollHeadInner > .dataTable > thead > tr > th {
  border-bottom-width: 0 !important;
}
.dataTables_borderWrap .dataTables_scrollBody,
.dataTables_borderWrap .dataTables_scrollHead {
  border: 1px solid #dddddd !important;
  border-width: 0 1px !important;
}
.dataTables_borderWrap .dataTables_scrollBody .table-bordered,
.dataTables_borderWrap .dataTables_scrollHead .table-bordered {
  border-left-width: 0;
  border-right-width: 0;
}
.dataTables_borderWrap .dataTables_scrollBody .table-bordered > thead > tr > th:first-child,
.dataTables_borderWrap .dataTables_scrollHead .table-bordered > thead > tr > th:first-child,
.dataTables_borderWrap .dataTables_scrollBody .table-bordered > tbody > tr > td:first-child,
.dataTables_borderWrap .dataTables_scrollHead .table-bordered > tbody > tr > td:first-child {
  border-left-width: 0;
}
.dataTables_borderWrap .dataTables_scrollBody .table-bordered > thead > tr > th:last-child,
.dataTables_borderWrap .dataTables_scrollHead .table-bordered > thead > tr > th:last-child,
.dataTables_borderWrap .dataTables_scrollBody .table-bordered > tbody > tr > td:last-child,
.dataTables_borderWrap .dataTables_scrollHead .table-bordered > tbody > tr > td:last-child {
  border-right-width: 0;
}
table.dataTable {
  clear: both;
  max-width: none !important;
}
table.dataTable th:active {
  outline: none;
}
div.dataTables_scrollHead table {
  margin-bottom: 0 !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
div.dataTables_scrollHead table thead tr:last-child th:first-child,
div.dataTables_scrollHead table thead tr:last-child td:first-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody tbody tr:first-child th,
div.dataTables_scrollBody tbody tr:first-child td {
  border-top: none;
}
div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}
.DTTT_Print .main-content {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.DTTT_Print .navbar-fixed-top + .main-container {
  padding-top: 0;
}
.tableTools-container {
  margin-bottom: 8px;
}
.tableTools-alert.gritter-item-wrapper {
  padding: 12px 11px 8px;
  z-index: 1999;
}
ul.ColVis_collection {
  z-index: 2002;
}
ul.ColVis_collection > li > a {
  padding: 0;
}
ul.ColVis_collection > li > a:focus {
  outline: none;
}
ul.ColVis_collection > li.ColVis_Special {
  border-top: 1px solid #DDD;
}
ul.ColVis_collection > li.ColVis_Special > a {
  padding: 6px 11px 7px;
  text-align: center;
}
ul.ColVis_collection label {
  margin: auto;
  padding: 6px 11px 7px;
  display: block;
  cursor: pointer;
}
div.ColVis_catcher {
  position: absolute;
  z-index: 1101;
}
div.ColVis_collectionBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 1100;
}
.fc-toolbar h2 {
  font-size: 22px;
  color: #65A0CE;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed hr,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #BCD4E5;
}
.fc-unthemed .fc-today {
  background: #FFC;
}
.fc-event {
  border-width: 0;
  color: #ffffff;
  padding: 1px 1px 2px 2px;
  border-radius: 0;
}
.fc-event:not([class*="label-"]) {
  background-color: #abbac3;
}
.fc-event.label-yellow {
  color: #996633;
}
.fc-event.label-light {
  color: #888888;
}
.label-yellow .fc-event {
  color: #996633;
}
.label-light .fc-event {
  color: #888;
}
[class*="label-"] > .fc-event,
[class*="label-"] > .fc-event > .fc-event-skin.fc-event-head {
  background-color: inherit;
}
.fc-event.ui-draggable-dragging {
  cursor: move;
}
.fc-event.fc-event-vert,
.fc-event-vert > .fc-event {
  padding: 0 0 1px;
}
.fc-day-number {
  color: #2E6589;
  opacity: 1;
  filter: alpha(opacity=100);
}
.fc-widget-header,
.fc .fc-axis {
  background: #ECF2F7;
  color: #8090A0;
}
.fc-event-hori,
.fc-event-vert {
  border-radius: 0 !important;
  border-color: transparent;
}
.fc-event-vert .fc-event-content {
  padding-left: 1px;
  padding-right: 1px;
}
.fc-event-vert .fc-event-time {
  padding: 0;
}
.fc-state-default {
  border: none;
}
.fc-state-default,
.fc-state-default .fc-button-inner {
  border: none;
  background-color: #abbac3;
  color: #FFF;
  background-image: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 0 !important;
  margin-left: 2px;
}
.fc-state-default .fc-button-effect {
  display: none;
}
.fc-state-disabled,
.fc-state-disabled .fc-button-inner {
  opacity: 0.75;
  filter: alpha(opacity=75);
  color: #DDD;
}
.fc-state-active,
.fc-state-active .fc-button-inner {
  border-color: #4F99C6;
  background-color: #6FB3E0;
}
.fc-state-hover,
.fc-state-hover .fc-button-inner {
  background-color: #8B9AA3;
}
.fc .fc-button-group > * {
  margin: 0 1px 0 0;
}
.external-event {
  margin: 6px 0;
  padding: 0;
  cursor: default;
  display: block;
  font-size: 13px;
  line-height: 28px;
  color: #ffffff;
}
.external-event:not([class*="label-"]) {
  background-color: #abbac3;
}
.external-event:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.external-event.ui-draggable-dragging {
  cursor: move;
}
.external-event.label-yellow {
  color: #996633;
}
.external-event.label-light {
  color: #888888;
}
.external-event > .ace-icon:first-child {
  display: inline-block;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
  font-size: 15px;
  border-right: 1px solid #FFF;
}
/**
.widget-main {
	.fc {
		position:relative;
		top:-40px;
		
		> .fc-header {
			position:relative;
			z-index:10;
		}
		
		.fc-header-space {
			padding-left:2px;
		}
	}
	
	.fc-header-title > h2 {
		font-size: floor(@base-font-size * 1.4);
		line-height: 36px;
	}
	
	.fc-content {
		top:-14px;
		z-index:11;
	}
	
	.fc-button-content {
		height:37px;
		line-height:36px;
	}
	
}
*/
@media only screen and (max-width: 480px) {
  .fc-header td {
    display: block;
    width: auto;
    text-align: left;
  }
}
.chosen-container + .help-inline {
  vertical-align: middle;
}
/**
.chosen-select {
  display: inline !important; //for validation plugin to work it must be displayed
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-width: 0;
}
*/
.chosen-container,
[class*="chosen-container"] {
  vertical-align: middle;
}
.chosen-container > .chosen-single,
[class*="chosen-container"] > .chosen-single {
  line-height: 28px;
  height: 32px;
  box-shadow: none;
  background: #FAFAFA;
}
.chosen-choices {
  box-shadow: none !important;
}
.chosen-container-single .chosen-single abbr {
  background: none;
}
.chosen-container-single .chosen-single abbr:after {
  content: "\f00d";
  display: inline-block;
  color: #888;
  font-family: FontAwesome;
  font-size: 13px;
  position: absolute;
  right: 0;
  top: -7px;
}
.chosen-container-single .chosen-single abbr:hover:after {
  color: #464646;
}
.chosen-container-single.chosen-disabled .chosen-single abbr:hover:after {
  color: #464646;
}
.chosen-single div b {
  background: none !important;
}
.chosen-single div b:before {
  content: "\f0d7";
  display: inline-block;
  color: #888;
  font-family: FontAwesome;
  font-size: 12px;
  position: relative;
  top: -1px;
  left: 1px;
}
.chosen-container-active.chosen-with-drop .chosen-single div b:before {
  content: "\f0d8";
}
.chosen-container-single .chosen-search {
  position: relative;
}
.chosen-container-single .chosen-search input[type="text"] {
  background: none;
  border-radius: 0;
  line-height: 28px;
  height: 28px;
}
.chosen-container-single .chosen-search:after {
  content: "\f002";
  display: inline-block;
  color: #888;
  font-family: FontAwesome;
  font-size: 14px;
  position: absolute;
  top: 8px;
  right: 12px;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  height: 25px;
}
.chosen-container-multi .chosen-choices li.search-choice {
  line-height: 16px;
  padding-bottom: 4px;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  background: none;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {
  content: "\f00d";
  display: inline-block;
  color: #888;
  font-family: FontAwesome;
  font-size: 13px;
  position: absolute;
  right: 2px;
  top: -1px;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
  text-decoration: none;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover:before {
  color: #464646;
}
.chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close:before {
  color: #464646;
}
.chosen-container .chosen-results-scroll-down span,
.chosen-container .chosen-results-scroll-up span {
  background: none;
}
.chosen-container .chosen-results-scroll-down span:before,
.chosen-container .chosen-results-scroll-up span:before {
  content: "\f0d7";
  display: inline-block;
  color: #888;
  font-family: FontAwesome;
  font-size: 12px;
  position: relative;
  top: -1px;
  left: 1px;
}
.chosen-container .chosen-results-scroll-up span:before {
  content: "\f0d8";
}
.chosen-container-active .chosen-single-with-drop div b:before {
  content: "\f0d8";
}
.chosen-rtl .chosen-search input[type="text"] {
  background: none;
}
.chosen-rtl .chosen-search:after {
  content: "";
  display: none;
}
.chosen-rtl .chosen-search:before {
  content: "\f002";
  display: inline-block;
  color: #888;
  font-family: FontAwesome;
  font-size: 14px;
  position: absolute;
  top: 9px;
  left: 12px;
}
/** chosen - etc */
.chosen-container-single .chosen-single {
  border-radius: 0;
}
.chosen-container .chosen-results li.highlighted {
  background: #316AC5;
  color: #FFF;
}
.chosen-container-single .chosen-drop {
  border-radius: 0;
  border-bottom: 3px solid #4492C9;
  border-color: #4492C9;
}
.chosen-single.chosen-single-with-drop,
.chosen-container-active .chosen-single {
  border-color: #4492C9;
}
.form-group.has-error .chosen-single {
  border-color: #f2a696 !important;
}
.form-group.has-info .chosen-single {
  border-color: #72aec2 !important;
}
.form-group.has-warning .chosen-single {
  border-color: #e3c94c !important;
}
.form-group.has-success .chosen-single {
  border-color: #9cc573 !important;
}
.chosen-container-active.chosen-with-drop .chosen-single {
  border-color: #4492C9;
}
.chosen-container .chosen-drop {
  display: none;
}
.chosen-container.chosen-with-drop .chosen-drop {
  left: auto;
  right: auto;
  display: block;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 144dpi) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices li.search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: none !important;
    background-repeat: no-repeat !important;
    background-size: auto !important;
  }
}
.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice {
  background-image: none;
  background-color: #91b8d0;
  color: #FFFFFF;
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 3px;
  margin-right: 3px;
  padding: 6px 22px 7px 9px;
  position: relative;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease 0s;
  vertical-align: baseline;
  white-space: nowrap;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 18px;
  height: auto;
  line-height: 25px;
  text-align: center;
}
.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {
  color: #FFF;
  position: static;
  font-size: 11px;
}
.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover:before {
  color: #FFF;
}
.tag-input-style + .chosen-container-multi.chosen-rtl .chosen-choices li.search-choice {
  padding: 6px 9px 7px 22px;
  margin-left: 0;
  margin-right: 3px !important;
}
.tag-input-style + .chosen-container-multi.chosen-rtl .chosen-choices li.search-choice .search-choice-close {
  right: auto;
  left: 0;
}
.select2-container .select2-choice {
  border-radius: 0;
  height: 32px;
  line-height: 28px;
}
.select2-container.select2-drop-above .select2-choice {
  border-radius: 0;
}
.select2-container[class*="input-"] {
  max-width: none;
}
.select2-container.input-mini {
  min-width: 100px;
}
.select2-container .select2-choice abbr,
.select2-search-choice-close {
  background: none;
}
.select2-container .select2-choice abbr:before,
.select2-search-choice-close:before {
  font-family: FontAwesome;
  font-size: 12px;
  display: inline;
  content: "\f00d";
  color: #888;
  position: relative;
  top: -1px;
}
.select2-container .select2-choice abbr:hover:before,
.select2-search-choice-close:hover:before {
  color: #555;
}
.select2-container .select2-choice abbr:before {
  top: -7px;
}
.select2-search-choice-close:hover {
  text-decoration: none !important;
}
.select2-drop {
  border-radius: 0;
  border: 1px solid #4492C9;
  border-width: 0 1px 3px;
}
.select2-drop.select2-drop-above {
  border-radius: 0;
}
.select2-container .select2-choice {
  background: #FAFAFA none;
}
.select2-container-active .select2-choice,
.select2-container-active .select2-choices,
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices,
.select2-container-multi.select2-container-active .select2-choices {
  border-color: #4492C9;
}
.select2-results .select2-highlighted {
  background: #316AC5;
}
.select2-container .select2-choice .select2-arrow {
  border-radius: 0;
  background: transparent none;
  border: none;
}
.select2-container .select2-choice .select2-arrow b {
  background: none;
}
.select2-container .select2-choice .select2-arrow b:before {
  font-family: FontAwesome;
  font-size: 12px;
  display: inline;
  content: "\f0d7";
  color: #888;
  position: relative;
  left: 5px;
}
.select2-dropdown-open .select2-choice .select2-arrow b:before {
  content: "\f0d8";
}
.select2-search .select2-input {
  background: #fff none;
  margin-top: 4px;
}
.select2-search:after {
  font-family: FontAwesome;
  font-size: 14px;
  display: inline;
  content: "\f002";
  color: #777;
  position: relative;
  top: 0;
  left: -20px;
  z-index: 0;
}
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background-image: none;
  background-color: #F6F6F6;
}
.select2-container-multi .select2-choices .select2-search-field input {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: none !important;
  font-size: 14px;
}
.select2-container-multi .select2-choices .select2-search-choice {
  line-height: 16px;
  padding-bottom: 4px;
}
.select2-container-active .select2-choice,
.select2-container-active .select2-choices,
.select2-container-multi.select2-container-active .select2-choices,
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-search input.select2-active {
  background-color: #FFF;
  position: relative;
  z-index: 1;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .select2-search input,
  .select2-search-choice-close,
  .select2-container .select2-choice abbr,
  .select2-container .select2-choice div b {
    background-image: none !important;
    background-size: auto !important;
  }
  .select2-search input {
    background-position: auto !important;
  }
}
.select2-container-active.select2-dropdown-open .select2-choice {
  background-image: -webkit-linear-gradient(top, #eeeeee 0%, #ffffff 100%);
  background-image: -o-linear-gradient(top, #eeeeee 0%, #ffffff 100%);
  background-image: linear-gradient(to bottom, #eeeeee 0%, #ffffff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffffffff', GradientType=0);
}
.select2-container-active.select2-drop-above .select2-choice {
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffeeeeee', GradientType=0);
}
.form-group.has-error .select2-choice,
.form-group.has-error .select2-choices {
  border-color: #f2a696 !important;
}
.form-group.has-info .select2-choice,
.form-group.has-info .select2-choices {
  border-color: #72aec2 !important;
}
.form-group.has-warning .select2-choice,
.form-group.has-warning .select2-choices {
  border-color: #e3c94c !important;
}
.form-group.has-success .select2-choice,
.form-group.has-success .select2-choices {
  border-color: #9cc573 !important;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .select2-search input,
  .select2-search-choice-close,
  .select2-container .select2-choice abbr,
  .select2-container .select2-choice .select2-arrow b {
    background-image: none !important;
    background-repeat: no-repeat !important;
    background-size: auto !important;
  }
  .select2-search input {
    background-position: auto !important;
  }
}
.select2-container-multi.tag-input-style .select2-choices .select2-search-choice {
  background-image: none;
  background-color: #91b8d0;
  color: #FFFFFF;
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 3px;
  margin-right: 0;
  padding: 6px 22px 7px 9px;
  position: relative;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease 0s;
  vertical-align: baseline;
  white-space: nowrap;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
.select2-container-multi.tag-input-style .select2-choices .select2-search-choice .select2-search-choice-close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  width: 18px;
  height: auto;
  line-height: 25px;
  text-align: center;
}
.select2-container-multi.tag-input-style .select2-choices .select2-search-choice .select2-search-choice-close:before {
  color: #FFF;
  position: static;
  font-size: 11px;
}
.select2-container-multi.tag-input-style .select2-choices .select2-search-choice .select2-search-choice-close:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.select2-container-multi.tag-input-style .select2-choices .select2-search-choice .select2-search-choice-close:hover:before {
  color: #FFF;
}
#colorbox:focus,
#colorbox:active {
  outline: none;
}
#cboxTopLeft,
#cboxTopCenter,
#cboxTopRight,
#cboxMiddleLeft,
#cboxMiddleRight,
#cboxBottomLeft,
#cboxBottomCenter,
#cboxBottomRight {
  background: none !important;
  opacity: 0;
}
#cboxContent {
  border: 12px solid #000;
  background-color: #FFF;
  padding: 7px;
}
#cboxOverlay {
  background: rgba(0, 0, 0, 0.95);
  background: #000;
}
#cboxCurrent {
  left: 64px;
  margin-bottom: 4px;
  font-size: 14px;
}
#cboxTitle {
  margin-bottom: 4px;
  font-size: 14px;
  color: #777;
}
#cboxNext,
#cboxPrevious,
#cboxClose {
  background: none;
  text-indent: 0;
  width: 26px;
  height: 26px;
  line-height: 22px;
  padding: 0 4px;
  text-align: center;
  border: 2px solid #999;
  border-radius: 16px;
  color: #666;
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 5px;
}
#cboxNext:hover,
#cboxPrevious:hover {
  color: #333;
  border-color: #666;
}
#cboxContent {
  overflow: visible;
}
#cboxClose {
  background-color: #000;
  border: 2px solid #FFF;
  border-radius: 32px;
  color: #FFF;
  font-size: 21px;
  height: 28px;
  width: 28px;
  padding-bottom: 2px;
  margin-left: 0;
  right: -14px;
  top: -14px;
}
#cboxLoadingOverlay {
  background: none !important;
}
#cboxLoadingGraphic {
  background: #FFF none !important;
  text-align: center;
}
#cboxLoadingGraphic > .ace-icon {
  display: inline-block;
  background-color: #FFF;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  position: relative;
  top: 48%;
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
  color: #FE7E3E;
}
.ace-spinner {
  display: inline-block;
}
.ace-spinner .spinbox-buttons.btn-group-vertical {
  min-width: 18px;
}
.ace-spinner .spinbox-buttons.btn-group-vertical > .btn {
  font-size: 10px;
  padding: 0;
  width: 22px;
  height: 16px;
  line-height: 8px;
  margin-left: 0;
}
.ace-spinner .spinbox-buttons.btn-group-vertical > .btn:first-child {
  margin-top: 0;
}
.ace-spinner .spinbox-buttons.btn-group-vertical > .btn > .ace-icon {
  margin: 0;
  padding: 0;
}
.ace-spinner .spinbox-buttons.btn-group-vertical > .btn + .btn {
  margin-top: 2px;
}
.ace-spinner .spinbox-buttons.btn-group-vertical > .btn-xs {
  height: 14px;
  line-height: 7px;
}
.ace-spinner .spinbox-buttons.btn-group-vertical > .btn-lg {
  height: 22px;
  line-height: 10px;
  width: 22px;
}
.ace-spinner .spinbox-buttons > button.btn.spinbox-up:active {
  top: -1px;
}
.ace-spinner:not(.touch-spinner) .spinbox-buttons > .btn > .ace-icon {
  margin-top: -1px;
}
.ace-spinner.touch-spinner .spinbox-buttons {
  margin: 0;
  font-size: 0;
}
.ace-spinner.touch-spinner .spinbox-buttons .btn-sm {
  width: 32px;
}
.ace-spinner.touch-spinner .spinbox-buttons .btn-xs {
  width: 24px;
}
.ace-spinner.touch-spinner .spinbox-buttons .btn-lg {
  width: 40px;
}
.ace-spinner.touch-spinner .spinbox-buttons > .btn {
  margin: 0 1px !important;
}
.ace-spinner.touch-spinner .spinbox-buttons > .btn-xs {
  padding-top: 3px;
  padding-bottom: 3px;
}
.ace-spinner.touch-spinner .spinbox-buttons > .btn > .ace-icon {
  vertical-align: middle;
  display: inline-block;
}
.steps {
  list-style: none;
  display: table;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}
.steps li {
  display: table-cell;
  text-align: center;
  width: 1%;
}
.steps li .step {
  border: 5px solid #ced1d6;
  color: #546474;
  font-size: 15px;
  border-radius: 100%;
  background-color: #FFF;
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 30px;
  text-align: center;
}
.steps li:before {
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  font-size: 0;
  overflow: hidden;
  border-top: 4px solid #CED1D6;
  position: relative;
  top: 21px;
  z-index: 1;
}
.steps li.last-child:before {
  max-width: 50%;
  width: 50%;
}
.steps li:last-child:before {
  max-width: 50%;
  width: 50%;
}
.steps li:first-child:before {
  max-width: 51%;
  left: 50%;
}
.steps li.active:before,
.steps li.complete:before,
.steps li.active .step,
.steps li.complete .step {
  border-color: #5293c4;
}
.steps li.complete .step {
  cursor: default;
  color: #FFF;
  -webkit-transition: transform ease 0.1s;
  -o-transition: transform ease 0.1s;
  transition: transform ease 0.1s;
}
.steps li.complete .step:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  content: "\f00c";
  background-color: #FFF;
  z-index: 3;
  font-family: FontAwesome;
  font-size: 17px;
  color: #87ba21;
}
.steps li.complete:hover .step {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  border-color: #80afd4;
}
.steps li.complete:hover:before {
  border-color: #80afd4;
}
.steps li .title {
  display: block;
  margin-top: 4px;
  max-width: 100%;
  color: #949ea7;
  font-size: 14px;
  z-index: 104;
  text-align: center;
  table-layout: fixed;
  word-wrap: break-word;
}
.steps li.complete .title,
.steps li.active .title {
  color: #2b3d53;
}
.step-content {
  position: relative;
}
.step-content .step-pane {
  display: none;
  min-height: 200px;
  padding: 4px 8px 12px;
}
.step-content .step-pane.active {
  display: block;
}
.wizard-actions {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .steps li .step {
    width: 30px;
    height: 30px;
    line-height: 24px;
    border-width: 3px;
  }
  .steps li:before,
  .steps li:after {
    border-width: 3px;
  }
  .steps li.complete .step:before {
    line-height: 24px;
    font-size: 13px;
  }
  .steps li:before {
    top: 16px;
  }
  .step-content .step-pane {
    padding: 4px 4px 6px;
    min-height: 150px;
  }
}
.tree {
  margin: auto;
  padding: 0 0 0 9px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.tree:before {
  display: inline-block;
  content: "";
  position: absolute;
  top: -20px;
  bottom: 16px;
  left: 0;
  z-index: 1;
  border: 1px dotted #67b2dd;
  border-width: 0 0 0 1px;
}
.tree .tree-branch-name,
.tree .tree-item-name {
  cursor: pointer;
}
.tree .tree-branch {
  width: auto;
  min-height: 20px;
  cursor: pointer;
}
.tree .tree-branch .tree-branch-header {
  position: relative;
  height: 20px;
  line-height: 20px;
}
.tree .tree-branch .tree-branch-header:hover {
  background-color: #F0F7FC;
}
.tree .tree-branch .tree-branch-header .tree-branch-name,
.tree .tree-item .tree-item-name {
  display: inline;
  z-index: 2;
}
.tree .tree-branch .tree-branch-header > .tree-branch-name > .ace-icon:first-child,
.tree .tree-item > .tree-item-name > .ace-icon:first-child {
  display: inline-block;
  position: relative;
  z-index: 2;
  top: -1px;
}
.tree .tree-branch > .tree-branch-header > .tree-branch-name > .tree-label {
  margin-left: 2px;
}
.tree .tree-branch > .tree-branch-header > .tree-branch-name > .ace-icon:first-child {
  margin: -2px 0 0 -2px;
}
.tree .tree-branch:last-child:after {
  display: inline-block;
  content: "";
  position: absolute;
  z-index: 1;
  top: 15px;
  bottom: 0;
  left: -15px;
  border-left: 1px solid #FFF;
}
.tree .tree-branch .tree-branch-children {
  margin: 0 0 0 23px;
  padding: 0;
  position: relative;
}
.tree .tree-branch .tree-branch-children:before {
  display: inline-block;
  content: "";
  position: absolute;
  z-index: 1;
  top: -14px;
  bottom: 16px;
  left: -14px;
  border: 1px dotted #67b2dd;
  border-width: 0 0 0 1px;
}
.tree .tree-item {
  position: relative;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
}
.tree .tree-item:hover {
  background-color: #F0F7FC;
}
.tree .tree-item > .tree-item-name > .ace-icon:first-child {
  margin-right: 3px;
}
.tree .tree-item > .tree-item-name > .tree-label > .ace-icon:first-child {
  margin-left: 3px;
  margin-right: 3px;
}
.tree .tree-item > .ace-icon:first-child {
  margin-top: -1px;
}
.tree .tree-branch,
.tree .tree-item {
  position: relative;
  list-style: none;
}
.tree .tree-branch:before,
.tree .tree-item:before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 14px;
  left: -13px;
  width: 18px;
  height: 0;
  border-top: 1px dotted #67b2dd;
  z-index: 1;
}
.tree .tree-selected {
  background-color: rgba(98, 168, 209, 0.1);
  color: #6398B0;
}
.tree .tree-selected:hover {
  background-color: rgba(98, 168, 209, 0.1);
}
.tree .tree-item,
.tree .tree-branch {
  border: 1px solid #FFF;
}
.tree .tree-branch .tree-branch-header {
  border-radius: 0;
}
.tree .tree-item,
.tree .tree-branch .tree-branch-header {
  margin: 0;
  padding: 5px;
  color: #4D6878;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.tree .tree-item > .tree-item-name > .ace-icon:first-child {
  color: #F9E8CE;
  width: 13px;
  height: 13px;
  line-height: 13px;
  font-size: 11px;
  text-align: center;
  border-radius: 3px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background-color: #FAFAFA;
  border: 1px solid #CCC;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.tree .tree-selected > .tree-item-name > .ace-icon:first-child {
  background-color: #F9A021;
  border-color: #F9A021;
  color: #FFF;
}
.tree .tree-plus.ace-icon:first-child,
.tree .tree-minus.ace-icon:first-child {
  display: inline-block;
  font-style: normal;
  border: 1px solid #DDD;
  vertical-align: middle;
  height: 11px;
  width: 11px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  text-align: center;
  border: 1px solid #8BAEBF;
  line-height: 10px;
  background-color: #FFF;
  position: relative;
  z-index: 2;
}
.tree .tree-plus.ace-icon:first-child:before,
.tree .tree-minus.ace-icon:first-child:before {
  content: "";
  display: block;
  width: 7px;
  height: 0;
  border-top: 1px solid #4D6878;
  position: absolute;
  top: 5px;
  left: 2px;
}
.tree .tree-plus.ace-icon:first-child:after {
  content: "";
  display: block;
  height: 7px;
  width: 0;
  border-left: 1px solid #4D6878;
  position: absolute;
  top: 2px;
  left: 5px;
}
.tree .tree-unselectable .tree-item > .tree-item-name > .tree-label > .ace-icon:first-child {
  color: #5084A0;
  width: 13px;
  height: 13px;
  line-height: 13px;
  font-size: 10px;
  text-align: center;
  border-radius: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.tree .ace-icon[class*="-down"] {
  transform: rotate(-45deg);
}
.tree .ace-icon[class*="-download"] {
  transform: none;
}
.tree .fa-spin {
  height: auto;
}
.tree .tree-loading {
  margin-left: 36px;
}
.tree img {
  display: inline;
  veritcal-align: middle;
}
.gritter-item-wrapper {
  background-image: none !important;
  box-shadow: 0 2px 10px rgba(50, 50, 50, 0.5);
  background: rgba(50, 50, 50, 0.92);
}
.gritter-item-wrapper.gritter-info {
  background: rgba(49, 81, 133, 0.92);
}
.gritter-item-wrapper.gritter-error {
  background: rgba(153, 40, 18, 0.92);
}
.gritter-item-wrapper.gritter-success {
  background: rgba(89, 131, 75, 0.92);
}
.gritter-item-wrapper.gritter-warning {
  background: rgba(190, 112, 31, 0.92);
}
.gritter-item-wrapper.gritter-light {
  background: rgba(245, 245, 245, 0.95);
  border: 1px solid #BBB;
}
.gritter-item-wrapper.gritter-light.gritter-info {
  background: rgba(232, 242, 255, 0.95);
}
.gritter-item-wrapper.gritter-light.gritter-info .gritter-item {
  color: #4A577D;
}
.gritter-item-wrapper.gritter-light.gritter-error {
  background: rgba(255, 235, 235, 0.95);
}
.gritter-item-wrapper.gritter-light.gritter-error .gritter-item {
  color: #894A38;
}
.gritter-item-wrapper.gritter-light.gritter-success {
  background: rgba(239, 250, 227, 0.95);
}
.gritter-item-wrapper.gritter-light.gritter-success .gritter-item {
  color: #416131;
}
.gritter-item-wrapper.gritter-light.gritter-warning {
  background: rgba(252, 248, 227, 0.95);
}
.gritter-item-wrapper.gritter-light.gritter-warning .gritter-item {
  color: #946446;
}
.gritter-item p {
  line-height: 1.8;
}
.gritter-top,
.gritter-bottom,
.gritter-item {
  background-image: none;
}
.gritter-close {
  left: auto;
  right: 3px;
  background-image: none;
  width: 18px;
  height: 18px;
  line-height: 17px;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 16px;
  color: #E17B67;
}
.gritter-close:before {
  font-family: FontAwesome;
  font-size: 16px;
  content: "\f00d";
}
.gritter-info .gritter-close {
  color: #FFA500;
}
.gritter-error .gritter-close,
.gritter-success .gritter-close,
.gritter-warning .gritter-close {
  color: #FFEA07;
}
.gritter-close:hover {
  color: #FFF !important;
}
.gritter-title {
  text-shadow: none;
}
.gritter-light .gritter-item,
.gritter-light .gritter-bottom,
.gritter-light .gritter-top,
.gritter-light .gritter-close {
  background-image: none;
  color: #444;
}
.gritter-light .gritter-title {
  text-shadow: none;
}
.gritter-light .gritter-close:hover {
  color: #8A3104 !important;
}
.gritter-center {
  position: fixed;
  left: 33%;
  right: 33%;
  top: 33%;
}
@media only screen and (max-width: 767px) {
  .gritter-center {
    left: 16%;
    right: 16%;
    top: 30%;
  }
}
@media only screen and (max-width: 480px) {
  .gritter-center {
    left: 30px;
    right: 30px;
  }
}
@media only screen and (max-width: 320px) {
  .gritter-center {
    left: 10px;
    right: 10px;
  }
}
.wysiwyg-editor {
  max-height: 250px;
  height: 250px;
  background-color: #F7F8FA;
  border-collapse: separate;
  border: 1px solid #BBC0CA;
  padding: 4px;
  box-sizing: content-box;
  overflow-y: scroll;
  overflow-x: hidden;
  outline: none;
}
.wysiwyg-editor:focus {
  background-color: #FFF;
}
.wysiwyg-toolbar {
  line-height: 33px;
  margin: 0 !important;
  position: relative;
}
.wysiwyg-toolbar .dropdown-menu {
  text-align: left;
}
.wysiwyg-toolbar .btn-group {
  float: none !important;
  font-size: 0;
}
.wysiwyg-toolbar .btn-group > .btn {
  float: none;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  margin-left: 1px;
  /**
		&.active:after {
			border-color: transparent;
			border-style: solid;
			border-top-color: inherit;
			border-width: 6px 14px;
			bottom: -13px;
			left: 0;
			right: 0;
		}
		*/
}
.wysiwyg-toolbar .btn-group > .btn > .ace-icon:first-child {
  font-size: 14px;
  width: 25px;
  max-width: 25px;
  display: inline-block;
  border-width: 1px !important;
}
.wysiwyg-toolbar .btn-group > .btn.dropdown-toggle > .ace-icon:last-child {
  margin-right: 4px;
}
.wysiwyg-style1 .btn-group > .btn,
.wysiwyg-style2 .btn-group > .btn,
.wysiwyg-style1 .btn-group > .inline > .btn,
.wysiwyg-style2 .btn-group > .inline > .btn {
  margin: 0 !important;
  background: #FFF !important;
  border-width: 0 !important;
  color: #ADB3BE !important;
  text-shadow: none !important;
}
.wysiwyg-style1 .btn-group > .btn.active,
.wysiwyg-style2 .btn-group > .btn.active,
.wysiwyg-style1 .btn-group > .inline > .btn.active,
.wysiwyg-style2 .btn-group > .inline > .btn.active {
  color: #5B80CE !important;
}
.wysiwyg-style1 .btn-group > .btn.active:after,
.wysiwyg-style2 .btn-group > .btn.active:after,
.wysiwyg-style1 .btn-group > .inline > .btn.active:after,
.wysiwyg-style2 .btn-group > .inline > .btn.active:after {
  display: none;
}
.wysiwyg-style1 .btn-group,
.wysiwyg-style2 .btn-group {
  position: relative;
}
.wysiwyg-style1 .btn-group:after,
.wysiwyg-style2 .btn-group:after {
  display: block;
  content: "";
  position: absolute;
  left: -2px;
  top: 6px;
  bottom: 6px;
  width: 0;
  max-width: 0;
  border-left: 1px solid #E1E6EA;
}
.wysiwyg-style1 .btn-group:first-child:after,
.wysiwyg-style2 .btn-group:first-child:after {
  display: none;
}
.wysiwyg-style2 {
  background-color: #E5E5E5;
}
.wysiwyg-style2 + .wysiwyg-editor {
  border-color: #DDD;
  background-color: #FFF;
  border-top: none;
}
.wysiwyg-style2 .btn-group > .btn,
.wysiwyg-style2 .btn-group > .inline > .btn {
  margin: 0 1px 0 0 !important;
  background: #FFF !important;
  border: none !important;
  color: #8D939E !important;
  text-shadow: none !important;
}
.wysiwyg-style2 .btn-group > .btn.active,
.wysiwyg-style2 .btn-group > .inline > .btn.active {
  color: #FFF !important;
  background: #6AAEDF !important;
}
.wysiwyg-style2 .btn-group:after {
  display: none;
}
.wysiwyg-toolbar .btn-colorpicker {
  width: 24px;
  height: 24px;
  position: relative;
  background: #87B87F;
  /* Old browsers */
  background: -moz-linear-gradient(top, #cf3e73 10%, #ffffff 20%, #2283c5 30%, #ffffff 40%, #87b87f 50%, #ffffff 60%, #ffb752 70%, #ffffff 80%, #d15b47 90%, #ffffff 100%);
  /*  FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #cf3e73), color-stop(20%, #ffffff), color-stop(30%, #2283c5), color-stop(40%, #ffffff), color-stop(50%, #87b87f), color-stop(60%, #ffffff), color-stop(70%, #ffb752), color-stop(80%, #ffffff), color-stop(90%, #d15b47), color-stop(100%, #ffffff));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #cf3e73 10%, #ffffff 20%, #2283c5 30%, #ffffff 40%, #87b87f 50%, #ffffff 60%, #ffb752 70%, #ffffff 80%, #d15b47 90%, #ffffff 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #cf3e73 10%, #ffffff 20%, #2283c5 30%, #ffffff 40%, #87b87f 50%, #ffffff 60%, #ffb752 70%, #ffffff 80%, #d15b47 90%, #ffffff 100%);
  /* Opera11.10+ */
  background: -ms-linear-gradient(top, #cf3e73 10%, #ffffff 20%, #2283c5 30%, #ffffff 40%, #87b87f 50%, #ffffff 60%, #ffb752 70%, #ffffff 80%, #d15b47 90%, #ffffff 100%);
  /* IE10+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CF3E73', endColorstr='#FFB752', GradientType=0);
  /* IE6-9 */
  background: linear-gradient(top, #cf3e73 10%, #ffffff 20%, #2283c5 30%, #ffffff 40%, #87b87f 50%, #ffffff 60%, #ffb752 70%, #ffffff 80%, #d15b47 90%, #ffffff 100%);
  /* W3C */
}
.wysiwyg-toolbar .dropdown-colorpicker > .dropdown-menu {
  top: auto;
}
.wysiwyg-toolbar input[type=file] {
  position: fixed;
  z-index: -10;
  opacity: 0;
  max-width: 0;
  max-height: 0;
  display: block;
}
.wysiwyg-toolbar .wysiwyg-choose-file {
  display: inline-block;
  width: auto;
  margin: 4px auto 0;
  padding-left: 5px;
  padding-right: 5px;
}
.wysiwyg-toolbar .dropdown-menu input[type=text] {
  margin-left: 8px;
  margin-bottom: 0;
}
.wysiwyg-toolbar .dropdown-menu input[type=text].form-control {
  min-width: 150px;
}
.wysiwyg-toolbar .dropdown-menu .btn {
  margin-right: 8px;
  margin-left: 8px;
}
.wysiwyg-style1 .btn-colorpicker {
  width: 20px;
  height: 20px;
  margin-left: 4px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* for adding image resize functionality in chrome and safari */
  .wysiwyg-editor img {
    display: inline !important;
  }
  .wysiwyg-editor .ui-wrapper {
    border: 1px dotted #D00;
    overflow: visible !important;
    /* because it's image only, so it's ok */
    display: inline-block !important;
    vertical-align: middle;
  }
  .wysiwyg-editor .ui-wrapper:after {
    content: "";
    display: block;
    position: absolute;
    right: -3px;
    bottom: -3px;
    width: 7px;
    height: 7px;
    border: 1px solid #D00;
    background-color: #FFF;
    z-index: 1;
  }
}
/* inside widget */
.widget-header .wysiwyg-toolbar {
  background-color: transparent;
}
.widget-header .wysiwyg-toolbar .btn-group > .btn,
.widget-header .wysiwyg-toolbar .btn-group > .inline > .btn {
  border-color: transparent;
  background: rgba(255, 255, 255, 0.25) !important;
  color: #FFF !important;
  min-width: 32px;
  border-width: 1px !important;
  border-radius: 4px !important;
  padding: 2px 1px 4px;
}
.widget-header .wysiwyg-toolbar .btn-group > .btn.active,
.widget-header .wysiwyg-toolbar .btn-group > .inline > .btn.active {
  background: rgba(0, 0, 0, 0.25) !important;
}
.widget-body .wysiwyg-editor {
  border-width: 0;
}
.wysiwyg-speech-input {
  width: 20px !important;
  color: transparent !important;
  background: transparent none !important;
  border-width: 0 !important;
  -moz-transform: scale(2.0, 2.0);
  -webkit-transform: scale(2.0, 2.0);
  -o-transform: scale(2.0, 2.0);
  -ms-transform: scale(2.0, 2.0);
  transform: scale(2.0, 2.0);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  position: absolute;
  right: 0;
  top: -10px;
  cursor: pointer;
}
.wysiwyg-speech-input:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.widget-body .md-header {
  margin-top: -30px;
  margin-left: 9px;
}
.widget-body .md-header .btn {
  border-color: transparent;
  background: rgba(255, 255, 255, 0.25) !important;
  color: #FFF !important;
  text-align: center;
  min-width: 32px;
  border-width: 1px !important;
  border-radius: 4px !important;
  padding: 2px 4px 4px;
}
.widget-body .md-header .btn > .ace-icon {
  font-size: 14px;
  width: 25px;
  max-width: 25px;
  display: inline-block;
}
.widget-body .md-header .btn-inverse {
  background: rgba(0, 0, 0, 0.25) !important;
  padding-right: 5px;
  margin-left: 4px;
}
.md-fullscreen-controls {
  display: none;
}
.widget-body .md-preview {
  padding: 8px;
  min-height: 200px;
}
.widget-body .md-input {
  border: none !important;
  box-shadow: none !important;
  display: block;
  margin-bottom: 0;
  background-color: rgba(48, 126, 204, 0.07);
  padding: 8px;
  width: 100%;
}
.widget-body .md-input:focus {
  background-color: #FFF;
  box-shadow: none !important;
}
.editable-container .popover-title {
  color: #438EB9;
}
.editable-click {
  border-bottom: 1px dashed #BBB;
  cursor: pointer;
  font-weight: normal;
}
img.editable-click {
  border: 1px dotted #BBB;
}
.editable-click:hover {
  border-color: #0088CC;
  color: #0088CC;
}
img.editable-click:hover {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.editable-buttons,
.editable-input {
  display: inline-block;
}
.editable-buttons {
  margin-left: 1px;
}
.editable-buttons .btn {
  padding: 0;
  width: 28px;
  line-height: 24px;
  border-width: 3px;
  font-size: 12px;
  margin: 0 1px 0 0;
}
.editable-buttons .btn > .ace-icon {
  margin: 0;
}
.editable-clear-x {
  cursor: pointer;
  color: #888;
  background: none;
}
.editable-clear-x:hover {
  color: #D15B47;
}
.editable-clear-x:before {
  display: inline-block;
  content: "\f057";
  font-family: FontAwesome;
  font-size: 15px;
  position: absolute;
  margin-top: -9px;
  width: 16px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.editable-input .ace-spinner {
  margin-right: 8px;
}
.editable-input .ace-spinner .spinner-input {
  width: 100%;
}
.editable-inline .editable-slider {
  margin-top: 10px;
  margin-right: 4px;
}
.editable-popup .editable-slider {
  display: block;
  margin-bottom: 16px;
  margin-top: 4px;
}
.editable-slider input[type=text] {
  display: none;
}
.editable-slider input[type=range] {
  outline: none !important;
}
.editable-input .ace-file-input {
  display: block;
}
.editable-image .ace-file-multiple .ace-file-container.selected {
  border-color: transparent;
}
.editable-image + .editable-buttons,
.editable-wysiwyg + .editable-buttons {
  display: block;
  text-align: center;
  margin-top: 8px;
}
.editable-wysiwyg {
  width: 95%;
}
.editable-wysiwyg .wysiwyg-editor {
  height: auto;
  overflow-y: hidden;
}
.editableform .input-append.dropdown-menu {
  display: none;
}
.editableform .open .input-append.dropdown-menu {
  display: block;
}
.editable-container .editableform {
  margin-bottom: 10px;
}
.editable-inline .editableform {
  margin-bottom: 0;
}
.editableform .control-group {
  display: block;
}
.editableform-loading {
  background: none;
}
.editableform-loading .ace-icon,
.editableform-loading .progress {
  position: relative;
  top: 35%;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.datepicker td,
.daterangepicker td,
.datepicker th,
.daterangepicker th {
  border-radius: 0 !important;
  font-size: 13px;
}
.datepicker td.active,
.daterangepicker td.active,
.datepicker td.active:hover,
.daterangepicker td.active:hover {
  background: #2283c5 !important;
}
.datepicker td.active.disabled,
.daterangepicker td.active.disabled,
.datepicker td.active.disabled:hover,
.daterangepicker td.active.disabled:hover {
  background: #8b9aa3 !important;
}
.datepicker td,
.datepicker th {
  min-width: 32px;
}
.datepicker-dropdown.datepicker-orient-bottom:after,
.datepicker-dropdown.datepicker-orient-bottom:before {
  top: auto;
}
.daterangepicker .calendar-date {
  border-radius: 0;
}
.datepicker-months .month,
.datepicker-years .year {
  border-radius: 0 !important;
}
.datepicker-months .month.active,
.datepicker-years .year.active,
.datepicker-months .month.active:hover,
.datepicker-years .year.active:hover,
.datepicker-months .month.active:focus,
.datepicker-years .year.active:focus,
.datepicker-months .month.active:active,
.datepicker-years .year.active:active {
  background-image: none !important;
  background-color: #2283c5 !important;
}
.bootstrap-timepicker-widget table td input {
  width: 32px;
}
.well .datepicker table tr td.day:hover {
  background-color: #7d8893;
  color: #FFF;
}
.bootstrap-timepicker-widget table td a:hover {
  border-radius: 0;
}
.daterangepicker.opensleft:before,
.daterangepicker.opensright:before {
  -moz-border-bottom-colors: rgba(0, 0, 0, 0.2);
}
.daterangepicker.opensleft:after,
.daterangepicker.opensright:after {
  -moz-border-bottom-colors: #fff;
}
.datepicker-dropdown:before {
  -moz-border-bottom-colors: rgba(0, 0, 0, 0.2);
}
.datepicker-dropdown:after {
  -moz-border-bottom-colors: #fff;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
  -moz-border-top-colors: #999;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
  -moz-border-top-colors: #FFF;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
  -moz-border-bottom-colors: rgba(0, 0, 0, 0.2);
}
.bootstrap-timepicker-widget.dropdown-menu:after {
  -moz-border-bottom-colors: #FFF;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  -moz-border-top-colors: #999;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  -moz-border-top-colors: #FFF;
}
.bootstrap-datetimepicker-widget [class=btn] {
  border-width: 0 !important;
  background-color: transparent !important;
  color: #7399b8 !important;
  text-shadow: none !important;
}
.bootstrap-datetimepicker-widget [class=btn]:hover {
  color: #1B6AAA !important;
}
.bootstrap-datetimepicker-widget .btn.btn-primary {
  border-width: 3px !important;
}
.bootstrap-datetimepicker-widget .picker-switch {
  margin-bottom: 2px;
}
.bootstrap-datetimepicker-widget .picker-switch .btn {
  width: 90% !important;
  background-color: #EEE !important;
  color: #478fca !important;
  font-size: 16px;
}
.bootstrap-datetimepicker-widget .picker-switch .btn:hover {
  background-color: #e3edf5 !important;
}
.bootstrap-datetimepicker-widget td span {
  border-radius: 0;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  color: #555 !important;
}
.ui-slider {
  background-color: #D5D5D5;
}
.ui-slider-horizontal {
  height: 9px;
}
.ui-slider-vertical {
  width: 9px;
}
.ui-slider .ui-slider-handle {
  border-radius: 0;
  width: 1.45em;
  height: 1.45em;
  background-color: #F8F8F8;
  border: 1px solid;
}
.ui-slider .ui-slider-handle:before,
.ui-slider .ui-slider-handle:after {
  display: block;
  content: "";
  position: absolute;
  top: 4px;
  left: 5px;
  width: 4px;
  height: 8px;
  border: 1px solid;
  border-width: 0 1px;
  border-color: inherit;
}
.ui-slider .ui-slider-handle:after {
  left: 8px;
  border-width: 0 1px 0 0;
}
.ui-slider .ui-slider-handle:hover {
  background-color: #FFF;
}
.ui-slider .ui-slider-handle:hover,
.ui-slider .ui-slider-handle:focus,
.ui-slider .ui-slider-handle:active {
  outline: none;
  -webkit-box-shadow: 1px 1px 1px 0px rgba(0,0,0,.3);
  box-shadow: 1px 1px 1px 0px rgba(0,0,0,.3);
}
.ui-slider-horizontal .ui-slider-handle {
  margin-left: -0.725em;
  top: -0.4em;
}
.ui-slider-vertical .ui-slider-handle {
  left: -0.35em;
  margin-bottom: -0.65em;
}
.ui-slider-small.ui-slider-horizontal {
  height: 5px;
}
.ui-slider-small.ui-slider-vertical {
  width: 5px;
}
.ui-slider-small .ui-slider-handle {
  border-radius: 100%;
  width: 17px;
  height: 17px;
  margin-bottom: -0.45em;
  left: -0.35em;
}
.ui-slider-small .ui-slider-handle:before,
.ui-slider-small .ui-slider-handle:after {
  height: 7px;
  left: 5px;
  width: 3px;
}
.ui-slider-small .ui-slider-handle:after {
  left: 7px;
}
.ui-slider-simple .ui-slider-handle:after,
.ui-slider-simple .ui-slider-handle:before {
  display: none;
}
/* colors */
.ui-slider-range {
  background-color: #4aa4ce;
}
.ui-slider-handle {
  outline: none !important;
  border-color: #4aa4ce !important;
}
.ui-state-disabled.ui-slider {
  background-color: #E5E5E5;
}
.ui-state-disabled .ui-slider-range {
  background-color: #8daebe;
}
.ui-state-disabled .ui-slider-handle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #8daebe !important;
}
.ui-slider-green .ui-slider-range {
  background-color: #8bbc67;
}
.ui-slider-green .ui-slider-handle {
  border-color: #8bbc67 !important;
}
.ui-slider-green.ui-state-disabled .ui-slider-range {
  background-color: #aab0a6;
}
.ui-slider-green.ui-state-disabled .ui-slider-handle {
  border-color: #aab0a6 !important;
}
.ui-slider-red .ui-slider-range {
  background-color: #d36e6e;
}
.ui-slider-red .ui-slider-handle {
  border-color: #d36e6e !important;
}
.ui-slider-red.ui-state-disabled .ui-slider-range {
  background-color: #c8acac;
}
.ui-slider-red.ui-state-disabled .ui-slider-handle {
  border-color: #c8acac !important;
}
.ui-slider-purple .ui-slider-range {
  background-color: #ac68ba;
}
.ui-slider-purple .ui-slider-handle {
  border-color: #ac68ba !important;
}
.ui-slider-purple.ui-state-disabled .ui-slider-range {
  background-color: #ada7ae;
}
.ui-slider-purple.ui-state-disabled .ui-slider-handle {
  border-color: #ada7ae !important;
}
.ui-slider-orange .ui-slider-range {
  background-color: #efad62;
}
.ui-slider-orange .ui-slider-handle {
  border-color: #efad62 !important;
}
.ui-slider-orange.ui-state-disabled .ui-slider-range {
  background-color: #e0c4a4;
}
.ui-slider-orange.ui-state-disabled .ui-slider-handle {
  border-color: #e0c4a4 !important;
}
.ui-slider-dark .ui-slider-range {
  background-color: #606060;
}
.ui-slider-dark .ui-slider-handle {
  border-color: #606060 !important;
}
.ui-slider-dark.ui-state-disabled .ui-slider-range {
  background-color: #7a7a7a;
}
.ui-slider-dark.ui-state-disabled .ui-slider-handle {
  border-color: #7a7a7a !important;
}
.ui-slider-pink .ui-slider-range {
  background-color: #d6487e;
}
.ui-slider-pink .ui-slider-handle {
  border-color: #d6487e !important;
}
.ui-slider-pink.ui-state-disabled .ui-slider-range {
  background-color: #c38ea2;
}
.ui-slider-pink.ui-state-disabled .ui-slider-handle {
  border-color: #c38ea2 !important;
}
.ui-datepicker {
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  height: 26px;
  min-width: 32px;
  max-width: 32px;
  text-align: center;
  cursor: pointer;
  color: transparent;
  line-height: 26px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.ui-datepicker .ui-datepicker-prev .ui-icon,
.ui-datepicker .ui-datepicker-next .ui-icon {
  color: transparent;
  visibility: hidden;
}
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  background-color: #EEE;
  text-decoration: none;
}
.ui-datepicker .ui-datepicker-prev:before,
.ui-datepicker .ui-datepicker-next:before {
  display: inline;
  font-family: FontAwesome;
  font-size: 14px;
  content: "\f060";
  color: #393939;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 2px;
}
.ui-datepicker .ui-datepicker-next:before {
  content: "\f061";
}
.ui-datepicker .ui-datepicker-prev-hover {
  left: 2px;
}
.ui-datepicker .ui-datepicker-next-hover {
  right: 2px;
}
.ui-datepicker td {
  padding: 0;
}
.ui-datepicker td > a,
.ui-datepicker td > span {
  display: inline-block;
  height: 22px;
  min-width: 24px;
  max-width: 24px;
  text-align: center;
  color: #393939;
  font-size: 13px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.ui-datepicker td > a:hover {
  background-color: #EEE;
}
.ui-datepicker td > a.ui-state-highlight {
  background-color: #D5E5EF;
}
.ui-datepicker td > a.ui-state-active {
  background-color: #2283c5;
  color: #FFF;
}
.ui-datepicker td > a.ui-priority-secondary {
  color: #888;
}
.ui-datepicker td > span {
  color: #999;
}
.ui-datepicker td .ui-datepicker-title select {
  height: 24px;
  line-height: 24px;
  padding: 2px 3px;
}
.ui-datepicker td .ui-datepicker-buttonpane {
  background-color: #DDD;
  height: 1px;
}
/* dialog */
.ui-widget-overlay {
  background: rgba(0, 0, 0, 0.25);
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  z-index: 1039 !important;
}
.ui-dialog,
.ui-jqdialog {
  z-index: 1040 !important;
  background-color: #FFF;
  padding: 0;
  border: 1px solid #DDD;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.ui-dialog .ui-dialog-titlebar,
.ui-jqdialog .ui-dialog-titlebar,
.ui-dialog .ui-jqdialog-titlebar,
.ui-jqdialog .ui-jqdialog-titlebar {
  background-color: #F1F1F1;
  font-size: 16px;
  color: #669fc7;
  padding: 0;
}
.ui-dialog .ui-dialog-title,
.ui-jqdialog .ui-dialog-title,
.ui-dialog .ui-jqdialog-title,
.ui-jqdialog .ui-jqdialog-title {
  float: none !important;
  width: auto;
}
.ui-dialog .widget-header,
.ui-jqdialog .widget-header {
  margin: 0;
  border-width: 0 0 1px 0;
}
.ui-dialog .ui-dialog-buttonpane,
.ui-jqdialog .ui-dialog-buttonpane,
.ui-dialog .ui-jqdialog-buttonpane,
.ui-jqdialog .ui-jqdialog-buttonpane {
  background-color: #eff3f8;
  border-top: 1px solid #e4e9ee;
}
.ui-dialog .ui-dialog-buttonpane button,
.ui-jqdialog .ui-dialog-buttonpane button,
.ui-dialog .ui-jqdialog-buttonpane button,
.ui-jqdialog .ui-jqdialog-buttonpane button {
  font-size: 14px;
}
.ui-dialog .ui-dialog-titlebar-close,
.ui-jqdialog .ui-dialog-titlebar-close,
.ui-dialog .ui-jqdialog-titlebar-close,
.ui-jqdialog .ui-jqdialog-titlebar-close {
  border: none;
  background: transparent;
  opacity: 0.4;
  color: #d15b47;
  padding: 0;
  top: 50%;
  right: 8px !important;
  text-align: center;
}
.ui-dialog .ui-dialog-titlebar-close:before,
.ui-jqdialog .ui-dialog-titlebar-close:before,
.ui-dialog .ui-jqdialog-titlebar-close:before,
.ui-jqdialog .ui-jqdialog-titlebar-close:before {
  content: "\f00d";
  display: inline;
  font-family: FontAwesome;
  font-size: 16px;
}
.ui-dialog .ui-dialog-titlebar-close:hover,
.ui-jqdialog .ui-dialog-titlebar-close:hover,
.ui-dialog .ui-jqdialog-titlebar-close:hover,
.ui-jqdialog .ui-jqdialog-titlebar-close:hover {
  opacity: 1;
  text-decoration: none;
  padding: 0;
}
.ui-dialog .ui-dialog-titlebar-close .ui-button-text,
.ui-jqdialog .ui-dialog-titlebar-close .ui-button-text,
.ui-dialog .ui-jqdialog-titlebar-close .ui-button-text,
.ui-jqdialog .ui-jqdialog-titlebar-close .ui-button-text {
  text-indent: 0;
  visibility: hidden;
}
.ui-dialog .widget-header .ui-dialog-titlebar-close,
.ui-jqdialog .widget-header .ui-dialog-titlebar-close,
.ui-dialog .widget-header .ui-jqdialog-titlebar-close,
.ui-jqdialog .widget-header .ui-jqdialog-titlebar-close {
  right: 10px !important;
}
/* accordion */
.ui-accordion .ui-accordion-header {
  color: #478fca;
  font-weight: normal;
  background-color: #F9F9F9;
  border: 1px solid #cdd8e3;
  padding: 8px 8px 9px 24px;
}
.ui-accordion .ui-accordion-header:hover {
  color: #6ea6cc;
  background-color: #f1f8fd;
}
.ui-accordion .ui-accordion-header.ui-state-active {
  color: #4c8fbd;
  background-color: #eef4f9;
  position: relative;
  font-weight: bold;
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  text-indent: 0;
  margin-top: 0;
  position: absolute;
  left: 10px;
  top: 7px;
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon:before {
  display: inline;
  font-family: FontAwesome;
  font-size: 15px;
  content: "\f0da";
}
.ui-accordion .ui-accordion-header.ui-state-active .ui-accordion-header-icon:before {
  content: "\f0d7";
  font-weight: normal;
}
.ui-accordion .ui-accordion-content {
  border: 1px solid #cdd8e3;
  border-top-width: 0;
  padding: 11px 16px;
}
/* tabs */
.ui-tabs .ui-tabs-nav {
  padding: 0;
  border-bottom: 1px solid #C5D0DC;
}
.ui-tabs .ui-tabs-nav li.ui-state-default > a {
  background-color: #F9F9F9;
  border: 1px solid #C5D0DC;
  border-bottom-width: 0;
  color: #999;
  line-height: 16px;
  margin-right: -1px;
  z-index: 11;
  padding: 8px 12px;
  position: relative;
  top: 2px;
}
.ui-tabs .ui-tabs-nav li > a:focus {
  outline: none;
}
.ui-tabs .ui-tabs-nav li.ui-tabs-active > a {
  background-color: #FFF;
  border: 1px solid;
  border-color: #4C8FBD #C5D0DC transparent;
  border-top-width: 2px;
  -webkit-box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.15);
  color: #576373;
  position: relative;
  top: 1px;
}
.ui-tabs .ui-tabs-panel {
  border: 1px solid #C5D0DC;
  border-top-width: 0;
  margin: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
}
/* menu */
.ui-menu {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 150px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 3px;
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  margin: auto;
}
.ui-menu .ui-menu-item {
  padding: 5px 10px 6px;
  color: #444;
  cursor: pointer;
  display: block;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ui-menu .ui-menu-item .ui-menu-icon {
  float: right;
  position: relative;
  left: auto;
  right: 4px;
  bottom: auto;
  text-indent: 0;
}
.ui-menu .ui-menu-item .ui-menu-icon:before {
  content: "\f105";
  font-family: FontAwesome;
  font-size: 14px;
  display: inline;
}
.ui-menu .ui-menu-item:hover,
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active,
.ui-menu .ui-menu-item:hover > .ui-menu-icon,
.ui-menu .ui-state-focus > .ui-menu-icon,
.ui-menu .ui-state-active > .ui-menu-icon {
  text-decoration: none;
  background-color: #4f99c6;
  color: #FFF;
  margin: auto;
  font-weight: normal;
}
.ui-menu .ui-menu-item:hover .ui-menu-icon,
.ui-menu .ui-state-focus .ui-menu-icon,
.ui-menu .ui-state-active .ui-menu-icon,
.ui-menu .ui-menu-item:hover > .ui-menu-icon .ui-menu-icon,
.ui-menu .ui-state-focus > .ui-menu-icon .ui-menu-icon,
.ui-menu .ui-state-active > .ui-menu-icon .ui-menu-icon {
  color: #FFF;
}
.ui-menu .ui-state-disabled,
.ui-menu .ui-state-disabled .ui-menu-icon {
  color: #999;
  cursor: default;
}
.ui-menu .ui-state-disabled:hover,
.ui-menu .ui-state-disabled.ui-state-focus,
.ui-menu .ui-state-disabled.ui-state-active,
.ui-menu .ui-state-disabled:hover .ui-menu-icon,
.ui-menu .ui-state-disabled.ui-state-focus .ui-menu-icon,
.ui-menu .ui-state-disabled.ui-state-active .ui-menu-icon {
  background-color: #FFF;
  color: #999;
}
/* auto complete */
.ui-autocomplete {
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.ui-autocomplete-category {
  padding: 6px;
  position: relative;
  background-color: #eef4f9;
  color: #478fca;
  font-weight: bolder;
  border: 1px solid #DAE6ED;
  border-width: 1px 0;
}
.ui-spinner-button {
  border-width: 0 !important;
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  width: 18px;
  color: #FFFFFF !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  display: inline-block;
  position: absolute;
  text-align: center;
  padding: 0;
}
.ui-spinner-button > .ace-icon {
  width: 18px;
  display: inline-block;
}
.ui-spinner-up {
  top: 0;
  right: 5px;
}
.ui-spinner-down {
  bottom: 3px;
  right: 5px;
}
.ui-spinner-input {
  margin-top: 0;
  padding: 5px;
  max-width: 100px;
  font-size: 14px;
}
.ui-tooltip {
  background-color: #444;
  color: #FFF;
}
.ui-progressbar {
  background-color: #f5f5f5;
  height: 22px;
}
.ui-progressbar .ui-progressbar-value {
  margin: 0;
}
.ui-progressbar .ui-progressbar-value[class="progress-bar"] {
  background-color: #2a91d8;
}
.ui-selectmenu-button {
  border: 1px solid #aaa;
}
.ui-selectmenu-button[aria-expanded=true] {
  border-color: #4492C9;
}
.ui-selectmenu-button span.ui-icon {
  text-indent: 0;
  margin-top: -10px;
}
.ui-selectmenu-button .ui-icon:before {
  content: "\f0d7";
  display: inline-block;
  color: #888;
  font-family: FontAwesome;
  font-size: 14px;
}
.ui-jqgrid .ui-jqgrid-view,
.ui-jqgrid .ui-paging-info,
.ui-jqgrid .ui-pg-table,
.ui-jqgrid .ui-pg-selbox {
  font-size: 13px;
}
.ui-jqgrid .ui-jqgrid-title {
  float: left;
  margin: 8px;
}
.ui-jqgrid .ui-jqgrid-title-rtl {
  float: right;
  margin: 8px;
}
.ui-jqgrid-view > .ui-jqgrid-titlebar {
  height: 40px;
  line-height: 24px;
  color: #FFF;
  background: #307ecc;
  padding: 0;
  font-size: 15px;
}
.ui-jqgrid tr.jqgrow.ui-row-rtl td:last-child {
  border-right: none;
  border-left: 1px solid #E1E1E1;
}
.ui-jqgrid .ui-jqgrid-hdiv {
  background-color: #eff3f8;
  border: 1px solid #D3D3D3;
  border-width: 1px 0 0 1px;
  line-height: 15px;
  font-weight: bold;
  color: #777;
  text-shadow: none;
}
.ui-jqgrid .ui-jqgrid-htable thead {
  background-color: #eff3f8;
}
.ui-jqgrid .ui-jqgrid-htable th span.ui-jqgrid-resize {
  height: 45px !important;
}
.ui-jqgrid .ui-jqgrid-htable th div {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ui-jqgrid-hdiv .ui-jqgrid-htable {
  border-top: none;
}
.ui-jqgrid-hdiv .ui-jqgrid-htable {
  border-top: 1px solid #E1E1E1;
}
.ui-jqgrid-titlebar {
  position: relative;
  top: 1px;
  z-index: 1;
}
.ui-jqgrid tr.jqgrow,
.ui-jqgrid tr.ui-row-ltr,
.ui-jqgrid tr.ui-row-rtl {
  border: none;
}
.ui-jqgrid tr.ui-row-ltr td,
.ui-jqgrid tr.ui-row-rtl td {
  border-bottom: 1px solid #E1E1E1;
  padding: 6px 4px;
  border-color: #E1E1E1;
}
.ui-jqgrid tr.ui-state-highlight.ui-row-ltr td {
  border-right-color: #C7D3A9;
}
.ui-jqgrid tr.ui-state-highlight.ui-row-rtl td {
  border-left-color: #C7D3A9;
}
.ui-jqgrid-btable .ui-widget-content.ui-priority-secondary {
  background-image: none;
  background-color: #F9F9F9;
  opacity: 1;
}
.ui-jqgrid-btable .ui-widget-content.ui-state-hover {
  background-image: none;
  background-color: #EFF4F7;
  opacity: 1;
}
.ui-jqgrid-btable .ui-widget-content.ui-state-highlight {
  background-color: #E4EFC9;
}
.ui-jqgrid .ui-jqgrid-pager {
  line-height: 15px;
  height: 55px;
  padding-top: 3px !important;
  padding-bottom: 5px !important;
  background-color: #eff3f8 !important;
  border-bottom: 1px solid #E1E1E1 !important;
  border-top: 1px solid #E1E1E1 !important;
}
.ui-jqgrid .ui-pg-input {
  font-size: inherit;
  width: 24px;
  height: 20px;
  line-height: 16px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  text-align: center;
  padding-top: 1px;
  padding-bottom: 1px;
}
.ui-jqgrid .ui-pg-selbox {
  display: block;
  height: 24px;
  width: 60px;
  margin: 0;
  padding: 1px;
  line-height: normal;
}
.ui-jqgrid .ui-jqgrid-htable th div {
  overflow: visible;
}
.ui-jqgrid .ui-pager-control {
  height: 50px;
  position: relative;
  padding-left: 9px;
  padding-right: 9px;
}
.ui-jqgrid .ui-jqgrid-toppager {
  height: auto !important;
  background-color: #eff3f8;
  border-bottom: 1px solid #E1E1E1 !important;
}
.ui-jqgrid .jqgrow .editable {
  max-width: 90%;
  max-width: calc(92%) !important;
}
.ui-pg-table .navtable .ui-corner-all {
  border-radius: 0;
}
.ui-jqgrid .ui-pg-button:hover {
  padding: 1px;
}
.ui-jqgrid .ui-pg-button .ui-separator {
  margin-left: 4px;
  margin-right: 4px;
  border-color: #C9D4DB;
}
.ui-jqgrid .ui-jqgrid-btable {
  border-left: 1px solid #E1E1E1;
}
.ui-jqgrid .ui-jqgrid-bdiv {
  border-top: 1px solid #E1E1E1;
}
.ui-jqgrid .loading {
  position: absolute;
  top: 45%;
  left: 45%;
  width: auto;
  height: auto;
  z-index: 101;
  padding: 6px;
  margin: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  background-color: #FFF;
  border: 2px solid #8EB8D1;
  color: #E2B018;
}
.ui-jqgrid .ui-search-toolbar {
  border-top: 1px solid #E1E1E1;
}
.ui-jqgrid .ui-jqgrid-labels {
  border-bottom: none;
  background: #F2F2F2;
  background-image: -webkit-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
  background-image: -o-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
  background-image: linear-gradient(to bottom, #f8f8f8 0%, #ececec 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8f8f8', endColorstr='#ffececec', GradientType=0);
  padding: 0 !important;
  border-left: 1px solid #E1E1E1 !important;
}
.ui-jqgrid .ui-jqgrid-labels th {
  border-right: 1px solid #E1E1E1 !important;
  text-align: left !important;
}
/* checkbox container */
.ui-jqgrid-labels th[id*="_cb"]:first-child > div {
  padding-top: 0;
  text-align: center !important;
}
.ui-jqgrid-sortable {
  padding-left: 4px;
  font-size: 13px;
  color: #777;
  font-weight: bold;
}
.ui-jqgrid-sortable:hover {
  color: #547ea8;
}
th[aria-selected=true] {
  background-image: -webkit-linear-gradient(top, #eff3f8 0%, #e3e7ed 100%);
  background-image: -o-linear-gradient(top, #eff3f8 0%, #e3e7ed 100%);
  background-image: linear-gradient(to bottom, #eff3f8 0%, #e3e7ed 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeff3f8', endColorstr='#ffe3e7ed', GradientType=0);
}
th[aria-selected=true] .ui-jqgrid-sortable {
  color: #307ecc;
}
.ui-jqgrid .ui-icon {
  text-indent: 0;
  color: #307ecc;
  float: none;
  right: 2px;
}
.rtl .ui-jqgrid .ui-icon {
  right: auto;
  left: 2px;
}
.ui-jqgrid .ui-icon.ui-state-disabled {
  color: #BBB;
}
.ui-jqgrid .ui-icon.ui-state-disabled:hover {
  padding: 0;
}
.ui-grid-ico-sort:before {
  display: inline;
  content: "\f0d7";
  font-family: FontAwesome;
  font-size: 12px;
}
.ui-icon-asc:before {
  content: "\f0d8";
}
.ui-pg-table > tbody > tr > .ui-pg-button > .ui-icon {
  display: inline-block;
  padding: 0;
  width: 24px;
  height: 24px;
  line-height: 22px;
  text-align: center;
  position: static;
  float: none;
  margin: 0 2px !important;
  color: #808080;
  border: 1px solid #CCC;
  background-color: #FFF;
  border-radius: 100%;
}
.ui-pg-table > tbody > tr > .ui-pg-button > .ui-icon:hover {
  color: #699AB5;
  border-color: #699AB5;
}
.ui-pg-table > tbody > tr > .ui-pg-button > .ui-icon:before {
  width: 20px;
  text-align: center;
  display: inline-block;
}
.ui-pg-table > tbody > tr > .ui-pg-button.ui-state-disabled .ui-icon {
  color: #B0B0B0;
  background-color: #F7F7F7;
  border-color: #DDD;
  -moz-transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -o-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
.ui-jqgrid-btable input,
.ui-jqgrid-btable textarea,
.ui-jqgrid-btable select {
  padding: 2px;
  width: auto;
  max-width: 100%;
  margin-bottom: 0;
}
.ui-jqgrid-btable select {
  padding: 1px;
  height: 25px;
  line-height: 25px;
}
.ui-pg-div .ui-icon {
  display: inline-block;
  width: 18px;
  float: none;
  position: static;
  text-align: center;
  opacity: 0.85;
  -webkit-transition: all 0.12s;
  -o-transition: all 0.12s;
  transition: all 0.12s;
  margin: 0 1px;
  vertical-align: middle;
  cursor: pointer;
  font-size: 17px;
}
.ui-pg-div .ui-icon:hover {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 1;
  position: static;
  margin: 0 1px;
}
.ui-pg-div .ui-icon:before {
  font-family: FontAwesome;
  display: inline;
}
.ui-jqgrid .ui-icon-pencil {
  color: #478FCA;
}
.ui-jqgrid .ui-icon-pencil:before {
  content: "\f040";
}
.ui-jqgrid .ui-icon-trash {
  color: #DD5A43;
}
.ui-jqgrid .ui-icon-trash:before {
  content: "\f014";
}
.ui-jqgrid .ui-icon-disk {
  color: #69AA46;
}
.ui-jqgrid .ui-icon-disk:before {
  content: "\f00c";
}
.ui-jqgrid .ui-icon-cancel {
  color: #DD5A43;
}
.ui-jqgrid .ui-icon-cancel:before {
  content: "\f00d";
}
.ui-jqdialog-content,
.ui-jqdialog .ui-jqdialog-content {
  font-size: 13px;
  padding: 4px 0 0;
}
.ui-jqdialog-content .formdata,
.ui-jqdialog .ui-jqdialog-content .formdata {
  font-size: 13px;
  padding: 6px 12px;
}
.ui-jqdialog-content .form-view-data,
.ui-jqdialog .ui-jqdialog-content .form-view-data {
  vertical-align: middle;
  font-size: 13px;
}
.ui-jqdialog-content[id*="alertcnt_"],
.ui-jqdialog .ui-jqdialog-content[id*="alertcnt_"] {
  padding: 8px 11px;
}
.ui-jqdialog-content .CaptionTD {
  font-size: 12px;
  text-align: right;
  color: #666;
}
.ui-jqdialog-content .FormData {
  border-bottom: 1px dotted #E8E8E8;
}
.ui-jqdialog-content .FormData:last-child {
  border-bottom: none;
}
.ui-jqdialog-content .FormData > td {
  padding-top: 6px;
  padding-bottom: 6px;
}
.ui-jqdialog-content input.FormElement {
  width: auto;
}
.ui-jqdialog-content select.FormElement {
  padding: 1px;
  height: 25px;
  line-height: 25px;
  width: auto;
}
.ui-jqdialog-content td.EditButton {
  padding: 8px;
}
.EditTable {
  background-color: #eff3f8;
  border-top: 1px solid #D6E1EA !important;
  padding: 8px;
}
.EditTable tr:first-child {
  display: none;
}
.EditTable .navButton .fm-button {
  float: none !important;
  width: auto !important;
  margin: 1px 1px 2px !important;
  background-color: transparent;
  border-radius: 100%;
}
.EditTable .navButton .fm-button:hover {
  background-color: transparent;
}
.EditTable .navButton .fm-button:focus {
  outline: none;
}
.EditTable .navButton .fm-button .ace-icon {
  display: inline-block;
  color: #999;
  border: 1px solid #AAA;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 100%;
  background-color: #FFF;
}
.EditTable .navButton .fm-button:hover .ace-icon {
  color: #699AB5;
  border-color: #699AB5;
}
.EditTable .navButton .fm-button.ui-state-disabled .ace-icon,
.EditTable .navButton .fm-button.ui-state-disabled:hover .ace-icon {
  color: #BBB;
  border-color: #CCC;
  -moz-transform: scale(0.88);
  -webkit-transform: scale(0.88);
  -o-transform: scale(0.88);
  -ms-transform: scale(0.88);
  transform: scale(0.88);
}
.FormGrid .EditTable {
  background-color: #FFF;
  border-top: none !important;
  padding: 0;
}
.FormGrid .EditTable tr:first-child {
  display: none;
}
.ui-jqgrid .ui-jqgrid-view input,
.ui-jqgrid .ui-jqgrid-view select,
.ui-jqgrid .ui-jqgrid-view textarea,
.ui-jqgrid .ui-jqgrid-view button {
  font-size: 13px;
}
.ui-jqdialog-content .searchFilter select {
  padding: 1px;
  height: 26px;
  line-height: 26px;
  width: auto;
  max-width: 95%;
  margin-bottom: 0;
}
.ui-jqdialog-content .searchFilter .input-elm {
  margin-bottom: 0;
  height: 18px;
  line-height: 18px;
  width: 95% !important;
  padding-left: 1px;
  padding-right: 1px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.ui-jqdialog-content .searchFilter table {
  margin-left: 4px;
}
.ui-jqdialog-content .searchFilter tr td {
  padding: 5px 0;
}
.ui-jqdialog-content .searchFilter .add-group,
.ui-jqdialog-content .searchFilter .add-rule,
.ui-jqdialog-content .searchFilter .delete-group {
  margin-left: 4px !important;
  font-size: 15px !important;
}
.ui-jqdialog-content .searchFilter .delete-rule {
  border: none;
  background-color: #FFF;
  color: #D15B47;
  font-size: 20px;
  width: 22px;
  line-height: 10px;
  padding: 0;
  text-shadow: none !important;
  display: inline-block;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  opacity: 0.85;
}
.ui-jqdialog-content .searchFilter .delete-rule:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  color: #B74635;
  opacity: 1;
}
.ui-jqdialog-content .searchFilter .queryresult {
  margin-bottom: 11px;
}
.ui-jqdialog-content .searchFilter .queryresult td.query {
  padding: 6px 11px;
  border: 1px solid #E1E1E1;
  background-color: #EEEEEE;
}
.ui-jqdialog-content .searchFilter .queryresult td.query:empty {
  display: none;
}
.ui-state-error {
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  color: #a94442;
  margin: 4px 4px 8px;
  padding: 6px 10px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  font-size: 13px;
}
.ui-jqdialog .ui-widget-header {
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffeeeeee', GradientType=0);
  border-image: none;
  border-bottom: 1px solid solid;
  color: #669FC7;
  min-height: 38px;
  position: relative;
}
.ui-jqdialog .ui-widget-header .ui-jqdialog-title {
  line-height: 38px;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  text-align: left;
}
.widget-header .ui-jqdialog-title {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ui-jqdialog .ui-widget-header .widget-header {
  border-bottom: none;
}
.ui-jqdialog .ui-jqdialog-titlebar {
  border-bottom: 1px solid #DDD !important;
}
.fm-button {
  margin: 0 4px;
}
.fm-button:not(.btn) {
  background-color: #abbac3;
  border-radius: 0 ;
  box-shadow: none;
  color: #FFFFFF ;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  line-height: 28px;
  padding: 0 12px 1px;
  margin: 0 8px;
  position: relative;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  vertical-align: middle;
}
.fm-button.ui-state-default:hover {
  background-color: #8b9aa3;
}
.ui-jqgrid .ui-jqgrid-htable .ui-search-toolbar th {
  height: 30px;
  padding-top: 2px;
  white-space: normal;
}
.ui-jqgrid .ui-jqgrid-htable .ui-search-toolbar th div {
  padding-top: 0;
  padding-bottom: 0;
  height: 30px;
  line-height: 26px;
}
.ui-jqgrid .ui-jqgrid-titlebar-close {
  top: 10%;
  height: auto;
  padding: 0;
  margin: 2px 8px 0 0;
  text-align: center;
  border-radius: 4px;
}
.ui-jqgrid .ui-jqgrid-titlebar-close:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.ui-jqgrid .ui-jqgrid-titlebar-close .ui-icon:before {
  display: inline-block;
  font-family: FontAwesome;
  content: "\f077";
  color: #FFF;
}
.ui-jqgrid .ui-jqgrid-titlebar-close .ui-icon-circle-triangle-s:before {
  content: "\f078";
}
.ui-jqgrid .tree-wrap-ltr {
  margin: 0 4px;
  float: none;
  display: inline;
}
.ui-jqgrid .tree-wrap-rtl {
  margin: 2px 4px 0;
}
.ui-jqgrid .ui-subgrid {
  border-bottom: 1px solid #E1E1E1;
  background-color: #F6FAFF;
}
.ui-jqgrid .ui-subgrid .ui-jqgrid-btable {
  background-color: #FFF;
}
.ui-jqgrid .ui-subgrid .ui-jqgrid .ui-jqgrid-hdiv {
  background-color: transparent;
  margin-top: 4px;
}
.ui-jqgrid .ui-subgrid .ui-jqgrid .ui-jqgrid-hdiv .ui-jqgrid-htable .ui-jqgrid-labels {
  border-bottom: 1px solid #E1E1E1;
  background: #F1F1F1;
}
.ui-jqgrid .ui-subgrid .ui-jqgrid .ui-jqgrid-hdiv .ui-jqgrid-htable th[aria-selected="true"] {
  background: #E5E9EF;
}
.ui-jqgrid .ui-subgrid .ui-jqgrid .ui-jqgrid-hdiv .ui-jqgrid-htable th .ui-jqgrid-sortable {
  font-size: 12px;
}
.ui-jqgrid .ui-subgrid .ui-jqgrid .ui-jqgrid-hdiv .ui-jqgrid-htable th div {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ui-jqgrid .ui-subgrid .ui-jqgrid .ui-jqgrid-hdiv .ui-jqgrid-htable th span.ui-jqgrid-resize {
  height: 36px !important;
}
.ui-jqgrid .ui-subgrid .ui-jqgrid .ui-jqgrid-bdiv {
  height: auto !important;
  max-height: 150px;
  margin-bottom: 4px;
  border-top-width: 0;
  border-bottom: 1px solid #E1E1E1;
}
.ui-jqgrid .ui-sgcollapsed > a:hover {
  text-decoration: none;
}
@media only screen and (max-width: 767px) {
  .ui-jqgrid .ui-jqgrid-pager {
    height: 90px;
  }
  .ui-jqgrid .ui-jqgrid-pager > .ui-pager-control {
    height: 85px;
    padding-top: 9px;
  }
  .ui-jqgrid .ui-jqgrid-pager > .ui-pager-control > .ui-pg-table > tbody > tr > td {
    vertical-align: top;
  }
  .ui-jqgrid .ui-jqgrid-pager > .ui-pager-control > .ui-pg-table > tbody > tr > td#grid-pager_center {
    width: 0 !important;
    position: static;
  }
  .ui-jqgrid .ui-jqgrid-pager > .ui-pager-control > .ui-pg-table > tbody > tr > td#grid-pager_center > .ui-pg-table {
    margin: 36px auto 0;
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 0) {
  .ui-jqgrid .ui-jqgrid-pager > .ui-pager-control > .ui-pg-table > tbody > tr > td#grid-pager_center > .ui-pg-table {
    width: 300px;
  }
}
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  line-height: 20px;
}
.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dd-list .dd-list {
  padding-left: 30px;
}
.dd-collapsed .dd-list {
  display: none;
}
.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  line-height: 20px;
}
.dd-handle,
.dd2-content {
  display: block;
  min-height: 38px;
  margin: 5px 0;
  padding: 8px 12px;
  background: #F8FAFF;
  border: 1px solid #DAE2EA;
  color: #7C9EB2;
  text-decoration: none;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dd-handle:hover,
.dd2-content:hover {
  color: #438EB9;
  background: #F4F6F7;
  border-color: #DCE2E8;
}
.dd-handle[class*="btn-"],
.dd2-content[class*="btn-"] {
  color: #FFF;
  border: none;
  padding: 9px 12px;
}
.dd-handle[class*="btn-"]:hover,
.dd2-content[class*="btn-"]:hover {
  opacity: 0.85;
  color: #FFF;
}
.dd2-handle + .dd2-content,
.dd2-handle + .dd2-content[class*="btn-"] {
  padding-left: 44px;
}
.dd-handle[class*="btn-"]:hover,
.dd2-content[class*="btn-"] .dd2-handle[class*="btn-"]:hover + .dd2-content[class*="btn-"] {
  color: #FFF;
}
.dd-item > button:hover ~ .dd-handle,
.dd-item > button:hover ~ .dd2-content {
  color: #438EB9;
  background: #F4F6F7;
  border-color: #DCE2E8;
}
.dd-item > button:hover ~ .dd-handle[class*="btn-"],
.dd-item > button:hover ~ .dd2-content[class*="btn-"] {
  opacity: 0.85;
  color: #FFF;
}
.dd2-handle:hover ~ .dd2-content {
  color: #438EB9;
  background: #F4F6F7;
  border-color: #DCE2E8;
}
.dd2-handle:hover ~ .dd2-content[class*="btn-"] {
  opacity: 0.85;
  color: #FFF;
}
.dd2-item.dd-item > button {
  margin-left: 34px;
}
.dd-item > button {
  display: block;
  position: relative;
  z-index: 1;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 1px 5px 5px;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
  top: 4px;
  left: 1px;
  color: #707070;
}
.dd-item > button:before {
  font-family: FontAwesome;
  content: '\f067';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
  font-weight: normal;
  font-size: 14px;
}
.dd-item > button[data-action="collapse"]:before {
  content: '\f068';
}
.dd-item > button:hover {
  color: #707070;
}
.dd-item.dd-colored > button,
.dd-item.dd-colored > button:hover {
  color: #EEE;
}
.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #F0F9FF;
  border: 2px dashed #BED2DB;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dd-empty {
  border-color: #AAA;
  border-style: solid;
  background-color: #e5e5e5;
}
.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 999;
  opacity: 0.8;
}
.dd-dragel > li > .dd-handle {
  color: #4B92BE;
  background: #F1F5FA;
  border-color: #D6E1EA;
  border-left: 2px solid #777;
  position: relative;
}
.dd-dragel > li > .dd-handle[class*="btn-"] {
  color: #FFF;
}
.dd-dragel > .dd-item > .dd-handle {
  margin-top: 0;
}
.dd-list > li[class*="item-"] {
  border-width: 0;
  padding: 0;
}
.dd-list > li[class*="item-"] > .dd-handle {
  border-left: 2px solid;
  border-left-color: inherit;
}
.dd-list > li > .dd-handle .sticker {
  position: absolute;
  right: 0;
  top: 0;
}
.dd2-handle,
.dd-dragel > li > .dd2-handle {
  left: 0;
  top: 0;
  width: 36px;
  margin: 0;
  border-width: 1px 1px 0 0;
  text-align: center;
  padding: 0 !important;
  line-height: 38px;
  height: 38px;
  background: #EBEDF2;
  border: 1px solid #DEE4EA;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.dd2-handle:hover,
.dd-dragel > li > .dd2-handle {
  background: #E3E8ED;
}
.dd2-content[class*="btn-"] {
  text-shadow: none !important;
}
.dd2-handle[class*="btn-"] {
  text-shadow: none !important;
  background: rgba(0, 0, 0, 0.1) !important;
  border-right: 1px solid #EEE;
}
.dd2-handle[class*="btn-"]:hover {
  background: rgba(0, 0, 0, 0.08) !important;
}
.dd-dragel .dd2-handle[class*="btn-"] {
  border-color: transparent;
  border-right-color: #EEE;
}
.dd2-handle.btn-yellow {
  text-shadow: none !important;
  background: rgba(0, 0, 0, 0.05) !important;
  border-right: 1px solid #FFF;
}
.dd2-handle.btn-yellow:hover {
  background: rgba(0, 0, 0, 0.08) !important;
}
.dd-dragel .dd2-handle.btn-yellow {
  border-color: transparent;
  border-right-color: #FFF;
}
.dd-item > .dd2-handle .drag-icon {
  display: none;
}
.dd-dragel > .dd-item > .dd2-handle .drag-icon {
  display: inline;
}
.dd-dragel > .dd-item > .dd2-handle .normal-icon {
  display: none;
}
.dropzone {
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.dropzone .dz-default.dz-message {
  background-image: none;
  font-size: 24px;
  text-align: center;
  line-height: 32px;
  left: 0;
  width: 100%;
  margin-left: auto;
}
.dropzone .dz-default.dz-message span {
  display: inline;
  color: #555;
}
.dropzone .dz-default.dz-message span .upload-icon {
  opacity: 0.7;
  filter: alpha(opacity=70);
  margin-top: 8px;
  cursor: pointer;
}
.dropzone .dz-default.dz-message span .upload-icon:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  background-image: none;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 100%;
  text-align: center;
  line-height: 35px;
}
.dropzone .dz-preview .dz-error-mark:before,
.dropzone-previews .dz-preview .dz-error-mark:before {
  font-family: FontAwesome;
  font-size: 30px;
  color: #DB6262;
  content: "\f00d";
}
.dropzone .dz-preview .dz-success-mark:before,
.dropzone-previews .dz-preview .dz-success-mark:before {
  font-family: FontAwesome;
  font-size: 30px;
  color: #6DA552;
  content: "\f00c";
}
.dropzone a.dz-remove,
.dropzone-previews a.dz-remove {
  border: none;
  border-radius: 0;
  color: #FFF;
  background: #D15B47;
  cursor: pointer;
}
.dropzone a.dz-remove:hover,
.dropzone-previews a.dz-remove:hover {
  color: #FFF;
  background: #B74635;
}
.dropzone .progress,
.dropzone-previews .progress {
  margin-bottom: 0;
}
.dropzone .dz-preview.dz-success .progress,
.dropzone-previews .dz-preview.dz-success .progress,
.dropzone .dz-preview.dz-error .progress,
.dropzone-previews .dz-preview.dz-error .progress {
  display: none;
}
input.typeahead,
input.tt-query,
input.tt-hint {
  min-width: 175px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #CCC;
  border-radius: 0;
  outline: none;
}
input.tt-hint,
.form-group input.tt-hint {
  background-color: #FFF !important;
  color: #B0B0B0 !important;
}
.tt-dropdown-menu {
  text-align: left;
  position: absolute;
  left: 0 !important;
  right: 0 !important;
  min-width: 175px;
  margin-top: 2px;
  padding: 8px 0;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.tt-suggestion {
  padding: 3px 12px 4px;
  font-size: 16px;
  line-height: 24px;
}
.tt-suggestion.tt-cursor {
  color: #FFF;
  background-color: #4F99C6;
  cursor: pointer;
}
.tt-suggestion p {
  margin: 0;
}
input.typeahead.scrollable ~ .tt-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.btn-group > .btn.moveall:first-child,
.btn-group > .btn.remove:first-child {
  margin: 0;
}
.btn-group > .btn.moveall:first-child + .btn.move,
.btn-group > .btn.remove:first-child + .btn.removeall {
  margin: 0;
}
.bootstrap-duallistbox-container .info {
  font-size: 12px;
}
.bootstrap-duallistbox-container .clear1,
.bootstrap-duallistbox-container .clear2 {
  font-size: 12px;
}
.multiselect-container > li > a {
  padding: 0;
}
.multiselect-container > li > a > label {
  padding: 7px 10px 7px 20px;
}
.cancel-on-png,
.cancel-off-png,
.star-on-png,
.star-off-png,
.star-half-png {
  font-size: 2em;
}
.cancel-on-png,
.cancel-off-png,
.star-on-png,
.star-off-png,
.star-half-png {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "FontAwesome";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  color: #777777;
}
.cancel-on-png {
  color: #dd5a43;
}
.cancel-on-png:before {
  content: "\f057";
}
.cancel-off-png {
  color: #e08374;
}
.cancel-off-png:before {
  content: "\f05c";
}
.star-on-png {
  color: #feb902;
}
.star-on-png:before {
  content: "\f005";
}
.star-off-png {
  color: #777777;
}
.star-off-png:before {
  content: "\f006";
}
.star-half-png {
  color: #feb902;
}
.star-half-png:before {
  content: "\f123";
}
/* custom animated icons */
.icon-animated-bell {
  display: inline-block;
  -moz-animation: ringing 2.0s 5 ease 1.0s;
  -webkit-animation: ringing 2.0s 5 ease 1.0s;
  -o-animation: ringing 2.0s 5 ease 1.0s;
  -ms-animation: ringing 2.0s 5 ease 1.0s;
  animation: ringing 2.0s 5 ease 1.0s;
  -moz-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}
@-moz-keyframes ringing {
  0% {
    -moz-transform: rotate(-15deg);
  }
  2% {
    -moz-transform: rotate(15deg);
  }
  4% {
    -moz-transform: rotate(-18deg);
  }
  6% {
    -moz-transform: rotate(18deg);
  }
  8% {
    -moz-transform: rotate(-22deg);
  }
  10% {
    -moz-transform: rotate(22deg);
  }
  12% {
    -moz-transform: rotate(-18deg);
  }
  14% {
    -moz-transform: rotate(18deg);
  }
  16% {
    -moz-transform: rotate(-12deg);
  }
  18% {
    -moz-transform: rotate(12deg);
  }
  20% {
    -moz-transform: rotate(0deg);
  }
}
@-webkit-keyframes ringing {
  0% {
    -webkit-transform: rotate(-15deg);
  }
  2% {
    -webkit-transform: rotate(15deg);
  }
  4% {
    -webkit-transform: rotate(-18deg);
  }
  6% {
    -webkit-transform: rotate(18deg);
  }
  8% {
    -webkit-transform: rotate(-22deg);
  }
  10% {
    -webkit-transform: rotate(22deg);
  }
  12% {
    -webkit-transform: rotate(-18deg);
  }
  14% {
    -webkit-transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-12deg);
  }
  18% {
    -webkit-transform: rotate(12deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
  }
}
@-ms-keyframes ringing {
  0% {
    -ms-transform: rotate(-15deg);
  }
  2% {
    -ms-transform: rotate(15deg);
  }
  4% {
    -ms-transform: rotate(-18deg);
  }
  6% {
    -ms-transform: rotate(18deg);
  }
  8% {
    -ms-transform: rotate(-22deg);
  }
  10% {
    -ms-transform: rotate(22deg);
  }
  12% {
    -ms-transform: rotate(-18deg);
  }
  14% {
    -ms-transform: rotate(18deg);
  }
  16% {
    -ms-transform: rotate(-12deg);
  }
  18% {
    -ms-transform: rotate(12deg);
  }
  20% {
    -ms-transform: rotate(0deg);
  }
}
@keyframes ringing {
  0% {
    transform: rotate(-15deg);
  }
  2% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-18deg);
  }
  6% {
    transform: rotate(18deg);
  }
  8% {
    transform: rotate(-22deg);
  }
  10% {
    transform: rotate(22deg);
  }
  12% {
    transform: rotate(-18deg);
  }
  14% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-12deg);
  }
  18% {
    transform: rotate(12deg);
  }
  20% {
    transform: rotate(0deg);
  }
}
.icon-animated-vertical {
  display: inline-block;
  -moz-animation: vertical 2.0s 5 ease 2.0s;
  -webkit-animation: vertical 2.0s 5 ease 2.0s;
  -o-animation: vertical 2.0s 5 ease 2.0s;
  -ms-animation: vertical 2.0s 5 ease 2.0s;
  animation: vertical 2.0s 5 ease 2.0s;
}
@-moz-keyframes vertical {
  0% {
    -moz-transform: translate(0, -3px);
  }
  4% {
    -moz-transform: translate(0, 3px);
  }
  8% {
    -moz-transform: translate(0, -3px);
  }
  12% {
    -moz-transform: translate(0, 3px);
  }
  16% {
    -moz-transform: translate(0, -3px);
  }
  20% {
    -moz-transform: translate(0, 3px);
  }
  22% {
    -moz-transform: translate(0, 0);
  }
}
@-webkit-keyframes vertical {
  0% {
    -webkit-transform: translate(0, -3px);
  }
  4% {
    -webkit-transform: translate(0, 3px);
  }
  8% {
    -webkit-transform: translate(0, -3px);
  }
  12% {
    -webkit-transform: translate(0, 3px);
  }
  16% {
    -webkit-transform: translate(0, -3px);
  }
  20% {
    -webkit-transform: translate(0, 3px);
  }
  22% {
    -webkit-transform: translate(0, 0);
  }
}
@-ms-keyframes vertical {
  0% {
    -ms-transform: translate(0, -3px);
  }
  4% {
    -ms-transform: translate(0, 3px);
  }
  8% {
    -ms-transform: translate(0, -3px);
  }
  12% {
    -ms-transform: translate(0, 3px);
  }
  16% {
    -ms-transform: translate(0, -3px);
  }
  20% {
    -ms-transform: translate(0, 3px);
  }
  22% {
    -ms-transform: translate(0, 0);
  }
}
@keyframes vertical {
  0% {
    transform: translate(0, -3px);
  }
  4% {
    transform: translate(0, 3px);
  }
  8% {
    transform: translate(0, -3px);
  }
  12% {
    transform: translate(0, 3px);
  }
  16% {
    transform: translate(0, -3px);
  }
  20% {
    transform: translate(0, 3px);
  }
  22% {
    transform: translate(0, 0);
  }
}
.icon-animated-hand-pointer {
  display: inline-block;
  -moz-animation: hand-pointer 2.0s 4 ease 2.0s;
  -webkit-animation: hand-pointer 2.0s 4 ease 2.0s;
  -o-animation: hand-pointer 2.0s 4 ease 2.0s;
  -ms-animation: hand-pointer 2.0s 4 ease 2.0s;
  animation: hand-pointer 2.0s 4 ease 2.0s;
}
@-moz-keyframes hand-pointer {
  0% {
    -moz-transform: translate(0, 0);
  }
  6% {
    -moz-transform: translate(5px, 0);
  }
  12% {
    -moz-transform: translate(0, 0);
  }
  18% {
    -moz-transform: translate(5px, 0);
  }
  24% {
    -moz-transform: translate(0, 0);
  }
  30% {
    -moz-transform: translate(5px, 0);
  }
  36% {
    -moz-transform: translate(0, 0);
  }
}
.icon-animated-wrench {
  display: inline-block;
  -moz-animation: wrenching 2.5s 4 ease;
  -webkit-animation: wrenching 2.5s 4 ease;
  -o-animation: wrenching 2.5s 4 ease;
  -ms-animation: wrenching 2.5s 4 ease;
  animation: wrenching 2.5s 4 ease;
  -moz-transform-origin: 90% 35%;
  -webkit-transform-origin: 90% 35%;
  -o-transform-origin: 90% 35%;
  -ms-transform-origin: 90% 35%;
  transform-origin: 90% 35%;
}
@-moz-keyframes wrenching {
  0% {
    -moz-transform: rotate(-12deg);
  }
  8% {
    -moz-transform: rotate(12deg);
  }
  10% {
    -moz-transform: rotate(24deg);
  }
  18% {
    -moz-transform: rotate(-24deg);
  }
  20% {
    -moz-transform: rotate(-24deg);
  }
  28% {
    -moz-transform: rotate(24deg);
  }
  30% {
    -moz-transform: rotate(24deg);
  }
  38% {
    -moz-transform: rotate(-24deg);
  }
  40% {
    -moz-transform: rotate(-24deg);
  }
  48% {
    -moz-transform: rotate(24deg);
  }
  50% {
    -moz-transform: rotate(24deg);
  }
  58% {
    -moz-transform: rotate(-24deg);
  }
  60% {
    -moz-transform: rotate(-24deg);
  }
  68% {
    -moz-transform: rotate(24deg);
  }
  75% {
    -moz-transform: rotate(0deg);
  }
}
@-webkit-keyframes wrenching {
  0% {
    -webkit-transform: rotate(-12deg);
  }
  8% {
    -webkit-transform: rotate(12deg);
  }
  10% {
    -webkit-transform: rotate(24deg);
  }
  18% {
    -webkit-transform: rotate(-24deg);
  }
  20% {
    -webkit-transform: rotate(-24deg);
  }
  28% {
    -webkit-transform: rotate(24deg);
  }
  30% {
    -webkit-transform: rotate(24deg);
  }
  38% {
    -webkit-transform: rotate(-24deg);
  }
  40% {
    -webkit-transform: rotate(-24deg);
  }
  48% {
    -webkit-transform: rotate(24deg);
  }
  50% {
    -webkit-transform: rotate(24deg);
  }
  58% {
    -webkit-transform: rotate(-24deg);
  }
  60% {
    -webkit-transform: rotate(-24deg);
  }
  68% {
    -webkit-transform: rotate(24deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
  }
}
@-o-keyframes wrenching {
  0% {
    -o-transform: rotate(-12deg);
  }
  8% {
    -o-transform: rotate(12deg);
  }
  10% {
    -o-transform: rotate(24deg);
  }
  18% {
    -o-transform: rotate(-24deg);
  }
  20% {
    -o-transform: rotate(-24deg);
  }
  28% {
    -o-transform: rotate(24deg);
  }
  30% {
    -o-transform: rotate(24deg);
  }
  38% {
    -o-transform: rotate(-24deg);
  }
  40% {
    -o-transform: rotate(-24deg);
  }
  48% {
    -o-transform: rotate(24deg);
  }
  50% {
    -o-transform: rotate(24deg);
  }
  58% {
    -o-transform: rotate(-24deg);
  }
  60% {
    -o-transform: rotate(-24deg);
  }
  68% {
    -o-transform: rotate(24deg);
  }
  75% {
    -o-transform: rotate(0deg);
  }
}
@-ms-keyframes wrenching {
  0% {
    -ms-transform: rotate(-12deg);
  }
  8% {
    -ms-transform: rotate(12deg);
  }
  10% {
    -ms-transform: rotate(24deg);
  }
  18% {
    -ms-transform: rotate(-24deg);
  }
  20% {
    -ms-transform: rotate(-24deg);
  }
  28% {
    -ms-transform: rotate(24deg);
  }
  30% {
    -ms-transform: rotate(24deg);
  }
  38% {
    -ms-transform: rotate(-24deg);
  }
  40% {
    -ms-transform: rotate(-24deg);
  }
  48% {
    -ms-transform: rotate(24deg);
  }
  50% {
    -ms-transform: rotate(24deg);
  }
  58% {
    -ms-transform: rotate(-24deg);
  }
  60% {
    -ms-transform: rotate(-24deg);
  }
  68% {
    -ms-transform: rotate(24deg);
  }
  75% {
    -ms-transform: rotate(0deg);
  }
}
@keyframes wrenching {
  0% {
    transform: rotate(-12deg);
  }
  8% {
    transform: rotate(12deg);
  }
  10% {
    transform: rotate(24deg);
  }
  18% {
    transform: rotate(-24deg);
  }
  20% {
    transform: rotate(-24deg);
  }
  28% {
    transform: rotate(24deg);
  }
  30% {
    transform: rotate(24deg);
  }
  38% {
    transform: rotate(-24deg);
  }
  40% {
    transform: rotate(-24deg);
  }
  48% {
    transform: rotate(24deg);
  }
  50% {
    transform: rotate(24deg);
  }
  58% {
    transform: rotate(-24deg);
  }
  60% {
    transform: rotate(-24deg);
  }
  68% {
    transform: rotate(24deg);
  }
  75% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes blinking {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}
@-webkit-keyframes blinking {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}
@-ms-keyframes blinking {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}
@keyframes blinking {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}
@-moz-keyframes pulsating {
  0% {
    -moz-transform: scale(1);
  }
  5% {
    -moz-transform: scale(0.75);
  }
  10% {
    -moz-transform: scale(1);
  }
  15% {
    -moz-transform: scale(1.25);
  }
  20% {
    -moz-transform: scale(1);
  }
  25% {
    -moz-transform: scale(0.75);
  }
  30% {
    -moz-transform: scale(1);
  }
  35% {
    -moz-transform: scale(1.25);
  }
  40% {
    -moz-transform: scale(1);
  }
}
@-webkit-keyframes pulsating {
  0% {
    -webkit-transform: scale(1);
  }
  5% {
    -webkit-transform: scale(0.75);
  }
  10% {
    -webkit-transform: scale(1);
  }
  15% {
    -webkit-transform: scale(1.25);
  }
  20% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.75);
  }
  30% {
    -webkit-transform: scale(1);
  }
  35% {
    -webkit-transform: scale(1.25);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@-ms-keyframes pulsating {
  0% {
    -ms-transform: scale(1);
  }
  5% {
    -ms-transform: scale(0.75);
  }
  10% {
    -ms-transform: scale(1);
  }
  15% {
    -ms-transform: scale(1.25);
  }
  20% {
    -ms-transform: scale(1);
  }
  25% {
    -ms-transform: scale(0.75);
  }
  30% {
    -ms-transform: scale(1);
  }
  35% {
    -ms-transform: scale(1.25);
  }
  40% {
    -ms-transform: scale(1);
  }
}
@keyframes pulsating {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(0.75);
  }
  10% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.25);
  }
  20% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.75);
  }
  30% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.25);
  }
  40% {
    transform: scale(1);
  }
}
.btn-scroll-up {
  border-width: 0;
  position: fixed;
  right: 2px;
  z-index: 99;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  opacity: 0;
  filter: alpha(opacity=0);
  bottom: -24px;
  visibility: hidden;
}
.btn-scroll-up.display {
  opacity: 0.7;
  filter: alpha(opacity=70);
  bottom: 2px;
  visibility: visible;
}
.btn-scroll-up:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-scroll-up:focus {
  outline: none;
}
@media (min-width: 768px) {
  .main-container.container > .btn-scroll-up {
    right: auto;
    margin-left: 714px;
  }
}
@media (min-width: 992px) {
  .main-container.container > .btn-scroll-up {
    right: auto;
    margin-left: 934px;
  }
}
@media (min-width: 1200px) {
  .main-container.container > .btn-scroll-up {
    right: auto;
    margin-left: 1134px;
  }
}
.ace-settings-container {
  position: absolute;
  right: 0;
  top: auto;
  z-index: 12;
}
.btn.btn-app.ace-settings-btn {
  float: left;
  display: block;
  text-align: center;
  border-radius: 6px 0 0 6px;
  opacity: 0.55;
  vertical-align: top;
  margin: 0;
}
.btn.btn-app.ace-settings-btn:hover,
.btn.btn-app.ace-settings-btn.open {
  opacity: 1;
}
.btn.btn-app.ace-settings-btn.btn-xs {
  width: 42px;
}
.ace-settings-box {
  display: block;
  float: left;
  max-width: 0;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  -moz-transform: translate(0,0);
  -webkit-transform: translate(0,0);
  -o-transform: translate(0,0);
  -ms-transform: translate(0,0);
  transform: translate(0,0);
  background-color: #FFF;
  border: 0 solid #ffb34b;
  -webkit-transition: max-width 0.25s linear 0s, max-height 0s linear 0.25s, padding 0s linear 0.25s, border-width 0s linear 0.25s;
  -o-transition: max-width 0.25s linear 0s, max-height 0s linear 0.25s, padding 0s linear 0.25s, border-width 0s linear 0.25s;
  transition: max-width 0.25s linear 0s, max-height 0s linear 0.25s, padding 0s linear 0.25s, border-width 0s linear 0.25s;
}
.ace-settings-box.open {
  max-width: 320px;
  max-height: 1000px;
  padding: 0 14px;
  border-width: 2px;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.ace-settings-box.open .ace-settings-item {
  z-index: auto;
  min-width: 140px;
}
.ace-settings-box .ace-settings-item {
  margin: 6px 0;
  color: #444;
  max-height: 24px;
  position: relative;
  z-index: -1;
  white-space: nowrap;
}
.ace-settings-box .ace-settings-item > label.lbl {
  font-size: 13px;
}
@media (max-width: 480px) {
  .ace-settings-container {
    text-align: right;
  }
  .ace-settings-box {
    float: none !important;
    text-align: left;
  }
  .btn.ace-settings-btn {
    float: none !important;
    display: inline-block;
  }
}
@media (max-width: 320px) {
  .ace-settings-box > .clearfix > .pull-left,
  .ace-settings-box > .clearfix > .pull-right {
    float: none !important;
  }
}
.grid2,
.grid3,
.grid4 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  margin: 0 1%;
  padding: 0 2%;
  float: left;
  border-left: 1px solid #E3E3E3;
}
.grid2:first-child,
.grid3:first-child,
.grid4:first-child {
  border-left: none;
}
.grid2 {
  width: 48%;
}
.grid3 {
  width: 31.33%;
}
.grid4 {
  width: 23%;
  padding: 0 1%;
}
.draggable-placeholder {
  border: 2px dashed #D9D9D9 !important;
  background-color: #F7F7F7 !important;
}
.easyPieChart,
.easy-pie-chart {
  position: relative;
  text-align: center;
}
.easyPieChart canvas,
.easy-pie-chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.knob-container {
  direction: ltr;
  text-align: left;
}
.tags {
  display: inline-block;
  padding: 4px 6px;
  color: #777777;
  vertical-align: middle;
  background-color: #FFF;
  border: 1px solid #d5d5d5;
  width: 206px;
}
.tags:hover {
  border-color: #b5b5b5;
}
.tags-hover,
.tags-hover:hover {
  border-color: #f59942;
  outline: 0;
}
.tags[class*="span"] {
  float: none;
  margin-left: 0;
}
.tags input[type="text"],
.tags input[type="text"]:focus {
  border: none;
  display: inline;
  outline: 0;
  margin: 0;
  padding: 0;
  line-height: 18px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
}
.tags .tag {
  display: inline-block;
  position: relative;
  font-size: 13px;
  font-weight: normal;
  vertical-align: baseline;
  white-space: nowrap;
  background-color: #91b8d0;
  color: #FFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  padding: 4px 22px 5px 9px;
  margin-bottom: 3px;
  margin-right: 3px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  /**
  &:nth-child(5n+1) {
	background-color:#48A2E0;
  }
  &:nth-child(5n+2) {
	background-color:#34C896;
  }
  &:nth-child(5n+3) {
	background-color:#B57BB3;
  }
  &:nth-child(5n+4) {
	background-color:#CC7DA8;
  }
  &:nth-child(5n+5) {
	background-color:#666;
  }
*/
}
.tags .tag:empty {
  display: none;
}
.tags .tag-important {
  background-color: #d15b47;
}
.tags .tag-warning {
  background-color: #ffb752;
}
.tags .tag-success {
  background-color: #87b87f;
}
.tags .tag-info {
  background-color: #6fb3e0;
}
.tags .tag-inverse {
  background-color: #555555;
}
.tags .tag .close {
  font-size: 15px;
  line-height: 20px;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #FFF;
  text-shadow: none;
  float: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 18px;
  text-align: center;
}
.tags .tag .close:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.page-content > .row .col-xs-12,
.page-content > .row .col-sm-12,
.page-content > .row .col-md-12,
.page-content > .row .col-lg-12 {
  float: left;
  max-width: 100%;
}
.col-xs-reset {
  width: auto;
  padding-left: 0;
  padding-right: 0;
  float: none !important;
}
@media (min-width: 768px) {
  .col-sm-reset {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    float: none !important;
  }
}
@media (min-width: 992px) {
  .col-md-reset {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .col-lg-reset {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    float: none !important;
  }
}
.jqstooltip,
.legendColorBox div {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.legendLabel {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 22px;
  padding-left: 2px;
  font-size: 10px;
}
@media only screen and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }
}
.navbar-fixed-top + .main-container {
  padding-top: 45px;
}
@media (max-width: 479px) {
  .navbar-fixed-top + .main-container {
    padding-top: 90px;
  }
  .navbar-fixed-top.navbar-collapse + .main-container {
    padding-top: 45px;
  }
}
@media only screen and (max-width: 360px) {
  .grid2,
  .grid3,
  .grid4 {
    float: none;
    display: block;
    width: 96%;
    border-left-width: 0;
    position: relative;
    margin-bottom: 11px;
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 4px;
  }
  .grid2 > [class*="pull-"],
  .grid3 > [class*="pull-"],
  .grid4 > [class*="pull-"] {
    float: none !important;
    display: inline-block;
    position: absolute;
    right: 11px;
    top: 0;
    margin-top: 0;
  }
  .grid2:last-child,
  .grid3:last-child,
  .grid4:last-child {
    border-bottom-width: 0;
  }
}
@media only screen and (max-width: 480px) {
  .hidden-480 {
    display: none !important;
  }
}
@media only screen and (max-width: 320px) {
  .hidden-320 {
    display: none !important;
  }
}
.no-skin {
  /**
	.nav-list > li.disabled.active:after {
		border-color: #999;
	}
	.nav-list li.disabled li.active > a:after,
	.nav-list li.active.disabled > a:after {
		-moz-border-right-colors: #999 !important;
		border-right-color: #999 !important;
	}
	*/
}
.no-skin .navbar .navbar-toggle {
  background-color: #75B3D7;
}
.no-skin .navbar .navbar-toggle:focus {
  background-color: #75B3D7;
  border-color: transparent;
}
.no-skin .navbar .navbar-toggle:hover {
  background-color: #61a8d1;
  border-color: rgba(255, 255, 255, 0.1);
}
.no-skin .navbar .navbar-toggle.display,
.no-skin .navbar .navbar-toggle[data-toggle=collapse]:not(.collapsed) {
  background-color: #4d9dcc;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
  border-color: rgba(255, 255, 255, 0.35);
}
.no-skin .sidebar {
  background-color: #f2f2f2;
  border-style: solid;
  border-color: #cccccc;
  border-width: 0 1px 0 0;
}
.no-skin .nav-list .open > a,
.no-skin .nav-list .open > a:hover,
.no-skin .nav-list .open > a:focus {
  background-color: #fafafa;
}
.no-skin .nav-list > li {
  border-color: #e5e5e5;
}
.no-skin .nav-list > li > a {
  background-color: #f8f8f8;
  color: #585858;
}
.no-skin .nav-list > li > a:focus {
  background-color: #f8f8f8;
  color: #1963aa;
}
.no-skin .nav-list > li:hover > a {
  background-color: #ffffff;
  color: #266cad;
}
.no-skin .nav-list > li.open > a {
  background-color: #fafafa;
  color: #1963aa;
}
.no-skin .nav-list > li.active > a {
  font-weight: bold;
  color: #2b7dbc;
}
.no-skin .nav-list > li.active > a,
.no-skin .nav-list > li.active > a:hover,
.no-skin .nav-list > li.active > a:focus {
  background-color: #ffffff;
}
.no-skin .nav-list > li .submenu {
  background-color: #ffffff;
  border-color: #e5e5e5;
}
.no-skin .nav-list > li .submenu > li > a {
  border-top-color: #e4e4e4;
  background-color: #ffffff;
  color: #616161;
}
.no-skin .nav-list > li .submenu > li > a:hover {
  color: #4b88b7;
  background-color: #f1f5f9;
}
.no-skin .nav-list > li .submenu > li.active > a {
  color: #2b7dbc;
}
.no-skin .nav-list > li .submenu > li.active > a > .menu-icon {
  color: #c86139;
}
.no-skin .nav-list > li .submenu > li.active.open > a > .menu-icon {
  color: inherit;
}
@media only screen and (min-width: 992px) {
  .no-skin .nav-list > li .submenu > li.active.hover > a.dropdown-toggle > .menu-icon {
    color: inherit;
  }
}
.no-skin .nav-list > li .submenu > li.active:not(.open) > a {
  background-color: #f5f7fa;
}
.no-skin .nav-list > li .submenu > li.active:not(.open) > a:hover {
  background-color: #f1f5f9;
}
.no-skin .nav-list > li > .submenu .open > a,
.no-skin .nav-list > li > .submenu .open > a:hover,
.no-skin .nav-list > li > .submenu .open > a:focus {
  border-color: #e4e4e4;
}
.no-skin .nav-list > li > .submenu li > .submenu > li a {
  color: #757575;
}
.no-skin .nav-list > li > .submenu li > .submenu > li a:hover {
  color: #4b88b7;
  background-color: #f1f5f9;
}
.no-skin .nav-list > li > .submenu li.open > a {
  color: #4b88b7;
}
.no-skin .nav-list > li > .submenu li > .submenu li.open > a,
.no-skin .nav-list > li > .submenu li > .submenu li.active > a {
  color: #4b88b7;
}
.no-skin .nav-list > li > .submenu:before,
.no-skin .nav-list > li > .submenu > li:before {
  border-color: #9dbdd6;
}
.no-skin .nav-list > li.active > .submenu:before,
.no-skin .nav-list > li.active > .submenu > li:before {
  border-color: #8eb3d0;
}
.no-skin .sidebar-toggle {
  background-color: #f3f3f3;
  border-color: #e0e0e0;
}
.no-skin .sidebar-toggle > .ace-icon {
  border-color: #bbbbbb;
  color: #aaaaaa;
  background-color: #ffffff;
}
.no-skin .sidebar-shortcuts {
  background-color: #fafafa;
}
.no-skin .sidebar-fixed .sidebar-shortcuts {
  border-color: #dddddd;
}
.no-skin .sidebar-shortcuts-mini {
  background-color: #ffffff;
}
.no-skin .nav-list li > .arrow:before {
  border-right-color: #B8B8B8;
  -moz-border-right-colors: #B8B8B8;
  border-width: 10px 10px 10px 0;
  left: -11px;
}
.no-skin .nav-list li > .arrow:after {
  border-right-color: #ffffff;
  -moz-border-right-colors: #ffffff;
  border-width: 10px 10px 10px 0;
  left: -10px;
}
.no-skin .nav-list > li.pull_up > .arrow:after {
  border-right-color: #ffffff !important;
  -moz-border-right-colors: #ffffff !important;
  border-width: 10px 10px 10px 0 !important;
  left: -10px !important;
}
.no-skin .nav-list > li.pull_up > .arrow:before {
  border-width: 10px 10px 10px 0 !important;
  left: -11px !important;
}
.no-skin .nav-list li.active > a:after {
  border-right-color: #2b7dbc;
  -moz-border-right-colors: #2b7dbc;
}
.no-skin .nav-list > li.active:after {
  display: block;
  content: "";
  position: absolute;
  right: -2px;
  top: -1px;
  bottom: 0;
  z-index: 1;
  border: 2px solid;
  border-width: 0 2px 0 0;
  border-color: #2b7dbc;
}
.no-skin .sidebar-scroll .nav-list > li.active:after {
  right: 0;
}
@media only screen and (max-width: 991px) {
  .no-skin .sidebar.responsive .nav-list > li.active.open > a:after,
  .no-skin .sidebar.responsive-max .nav-list > li.active.open > a:after {
    display: block;
  }
  .no-skin .sidebar.responsive .nav-list li li.active > a:after,
  .no-skin .sidebar.responsive-max .nav-list li li.active > a:after {
    display: none;
  }
  .no-skin .sidebar.responsive .nav-list > li.active:after,
  .no-skin .sidebar.responsive-max .nav-list > li.active:after {
    height: 41px;
  }
}
.no-skin .sidebar.menu-min .nav-list > li > a > .menu-text {
  background-color: #f5f5f5;
  -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
  box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
  border-color: #cccccc;
}
.no-skin .sidebar.menu-min .nav-list > li > a.dropdown-toggle > .menu-text {
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
}
.no-skin .sidebar.menu-min .nav-list > li.active > .submenu {
  border-left-color: #83b6d1;
}
.no-skin .sidebar.menu-min .nav-list > li > .submenu {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-top-color: #e6e6e6;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
}
.no-skin .sidebar.menu-min .nav-list > li > .arrow:after {
  border-right-color: #f5f5f5;
  -moz-border-right-colors: #f5f5f5;
  border-width: 8px 8px 8px 0;
  left: -8px;
}
.no-skin .sidebar.menu-min .nav-list > li > .arrow:before {
  border-width: 8px 8px 8px 0;
  left: -9px;
}
.no-skin .sidebar.menu-min .nav-list > li.active > .arrow:before {
  border-right-color: #5a9ec2;
  -moz-border-right-colors: #5a9ec2;
}
.no-skin .sidebar.menu-min .nav-list > li.active > a > .menu-text {
  border-left-color: #83b6d1;
}
.no-skin .sidebar.menu-min .sidebar-shortcuts-large {
  background-color: #ffffff;
  -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
  box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
  border-color: #cccccc;
}
.no-skin .sidebar.menu-min .sidebar-toggle > .ace-icon {
  border-color: #b1b1b1;
}
@media (max-width: 991px) {
  .no-skin .sidebar.responsive-min .nav-list > li > a > .menu-text {
    background-color: #f5f5f5;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    border-color: #cccccc;
  }
  .no-skin .sidebar.responsive-min .nav-list > li > a.dropdown-toggle > .menu-text {
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
  }
  .no-skin .sidebar.responsive-min .nav-list > li.active > .submenu {
    border-left-color: #83b6d1;
  }
  .no-skin .sidebar.responsive-min .nav-list > li > .submenu {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-top-color: #e6e6e6;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
  }
  .no-skin .sidebar.responsive-min .nav-list > li > .arrow:after {
    border-right-color: #f5f5f5;
    -moz-border-right-colors: #f5f5f5;
    border-width: 8px 8px 8px 0;
    left: -8px;
  }
  .no-skin .sidebar.responsive-min .nav-list > li > .arrow:before {
    border-width: 8px 8px 8px 0;
    left: -9px;
  }
  .no-skin .sidebar.responsive-min .nav-list > li.active > .arrow:before {
    border-right-color: #5a9ec2;
    -moz-border-right-colors: #5a9ec2;
  }
  .no-skin .sidebar.responsive-min .nav-list > li.active > a > .menu-text {
    border-left-color: #83b6d1;
  }
  .no-skin .sidebar.responsive-min .sidebar-shortcuts-large {
    background-color: #ffffff;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    border-color: #cccccc;
  }
  .no-skin .sidebar.responsive-min .sidebar-toggle > .ace-icon {
    border-color: #b1b1b1;
  }
}
@media only screen and (min-width: 992px) {
  .no-skin .nav-list li.hover > .submenu {
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    border-color: #cccccc;
  }
  .no-skin .nav-list li.hover > .submenu > li.active > a {
    background-color: #f5f5f5;
  }
  .no-skin .nav-list li.hover > .submenu > li:hover > a {
    background-color: #eef3f7;
    color: #2e7db4;
  }
}
@media only screen and (min-width: 992px) and (max-width: 991px) {
  .no-skin .sidebar.navbar-collapse .nav-list li li.hover.active.open > a {
    background-color: #ffffff;
  }
  .no-skin .sidebar.navbar-collapse .nav-list li li.hover:hover > a {
    background-color: #ffffff;
  }
  .no-skin .sidebar.navbar-collapse .nav-list li li.hover > a:hover,
  .no-skin .sidebar.navbar-collapse .nav-list li li.hover.open > a:hover,
  .no-skin .sidebar.navbar-collapse .nav-list li li.hover.open.active > a:hover {
    background-color: #f1f5f9;
  }
  .no-skin .sidebar.navbar-collapse .nav-list > li .submenu > li.active.hover > a.dropdown-toggle > .menu-icon {
    color: #c86139;
  }
  .no-skin .sidebar.navbar-collapse .nav-list > li .submenu > li.active.open.hover > a.dropdown-toggle > .menu-icon {
    color: inherit;
  }
}
@media only screen and (min-width: 992px) {
  .no-skin .sidebar.navbar-collapse .nav-list > li.open.hover:not(:hover):not(:focus):not(.active) > a {
    color: #585858;
  }
  .no-skin .sidebar.navbar-collapse .nav-list > li.open.hover:not(:hover):not(:focus):not(.active) > a > .arrow {
    color: inherit;
  }
  .no-skin .sidebar.navbar-collapse .nav-list > li.open.hover:hover > a {
    background-color: #ffffff;
  }
  .no-skin .sidebar.navbar-collapse .nav-list > li > .submenu li.open.hover:not(:hover):not(:focus):not(.active) > a {
    color: #616161;
  }
  .no-skin .sidebar.navbar-collapse .nav-list > li > .submenu li.open.hover:not(:hover):not(:focus):not(.active) > a > .arrow {
    color: inherit;
  }
}
@media only screen and (min-width: 992px) and (max-width: 991px) {
  .no-skin .sidebar.navbar-collapse .nav-list li.hover > .submenu {
    border-top-color: #e5e5e5;
    background-color: #ffffff;
  }
  .no-skin .nav-list li.hover > .submenu > li.active:not(.open) > a {
    background-color: #f5f7fa;
  }
  .no-skin .nav-list li.hover > .submenu > li.active:not(.open) > a:hover {
    background-color: #f1f5f9;
  }
}
@media only screen and (min-width: 992px) {
  .no-skin .sidebar.h-sidebar {
    background-color: #f8f8f8;
  }
  .no-skin .sidebar.h-sidebar:before {
    background-color: #e4e4e4;
    -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.05) inset;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.05) inset;
  }
  .no-skin .sidebar.h-sidebar .nav-list > li:hover,
  .no-skin .sidebar.h-sidebar .nav-list > li:hover + li {
    border-left-color: #cedde5;
  }
  .no-skin .sidebar.h-sidebar .nav-list > li:last-child:hover {
    border-right-color: #cedde5;
  }
  .no-skin .sidebar.h-sidebar .nav-list > li.active,
  .no-skin .sidebar.h-sidebar .nav-list > li.active + li,
  .no-skin .sidebar.h-sidebar .nav-list > li:hover + li.active {
    border-left-color: #79b0ce;
  }
  .no-skin .sidebar.h-sidebar .nav-list > li.active:last-child {
    border-right-color: #79b0ce;
  }
  .no-skin .sidebar.h-sidebar .nav-list > li.active:before {
    background-color: #2b7dbc;
  }
  .no-skin .sidebar.h-sidebar .nav-list > li.active > a:after {
    border-width: 0 0 2px 0;
    border-color: transparent;
    border-bottom-color: #FFF;
    left: 0;
    right: 0;
    top: auto;
    bottom: -2px;
  }
  .no-skin .sidebar.h-sidebar .sidebar-shortcuts-large {
    background-color: #FFF;
    border-color: #cccccc;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
  }
  .no-skin .sidebar.h-sidebar .sidebar-shortcuts-large:after {
    border-bottom-color: #FFF;
    -moz-border-bottom-colors: #FFF;
  }
  .no-skin .sidebar.h-sidebar .nav-list > li.hover > .submenu {
    border-color: #cccccc;
  }
  .no-skin .sidebar.h-sidebar.menu-min .nav-list > li.hover > .submenu {
    border-top-color: #e6e6e6;
  }
  .no-skin .sidebar.h-sidebar .nav-list > li.hover > .arrow:after {
    border-color: transparent;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    -moz-border-left-colors: none;
    border-bottom-color: #FFF;
    -moz-border-bottom-colors: #FFF;
  }
  .no-skin .sidebar.h-sidebar .nav-list > li.hover > .arrow:before {
    border-color: transparent;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    -moz-border-left-colors: none;
    -moz-border-bottom-colors: #B8B8B8;
    border-bottom-color: #B8B8B8;
  }
  .no-skin .sidebar.h-sidebar.menu-min .nav-list > li.hover > .arrow:after {
    -moz-border-bottom-colors: #F5F5F5;
    border-bottom-color: #F5F5F5;
  }
  .no-skin .sidebar.h-sidebar.menu-min .nav-list > li.active > a > .menu-text {
    border-left-color: #cccccc;
  }
}
@media only screen and (max-width: 991px) {
  .no-skin .sidebar {
    border-width: 0 1px 1px 0;
    border-top-color: #d6d6d6;
  }
  .no-skin .menu-toggler + .sidebar.responsive {
    border-top-width: 1px;
  }
  .no-skin .sidebar.responsive-min {
    border-width: 0 1px 0 0;
  }
  .no-skin .sidebar.navbar-collapse {
    border-width: 0;
    border-bottom-width: 1px !important;
    border-bottom-color: #cccccc;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
  }
  .no-skin .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu {
    background-color: #ffffff;
  }
}
.no-skin .sidebar-scroll .sidebar-shortcuts {
  border-bottom-color: #dddddd;
}
.no-skin .sidebar-scroll .sidebar-toggle {
  border-top-color: #dddddd;
}
.no-skin .main-container .menu-toggler {
  background-color: #444444;
}
.no-skin .main-container .menu-toggler:before {
  border-top-color: #87b87f;
  border-bottom-color: #6fb3e0;
}
.no-skin .main-container .menu-toggler:after {
  border-top-color: #ffa24d;
  border-bottom-color: #d15b47;
}
.no-skin .main-container .menu-toggler > .toggler-text {
  border-top-color: #444444;
  -moz-border-top-colors: #444444;
}
.no-skin .nav-list > li.disabled:before {
  display: none !important;
}
.no-skin .nav-list > li.disabled > a {
  background-color: #ebebeb !important;
  color: #656565 !important;
}
.no-skin .nav-list li .submenu > li.disabled > a,
.no-skin .nav-list li.disabled .submenu > li > a {
  background-color: #f2f2f2 !important;
  color: #7a7a7a !important;
  cursor: not-allowed !important;
}
.no-skin .nav-list li .submenu > li.disabled > a > .menu-icon,
.no-skin .nav-list li.disabled .submenu > li > a > .menu-icon {
  display: none;
}
.nav-list li.highlight li.active > a:after,
.nav-list li li.highlight.active > a:after,
.nav-list li.highlight li.active > a:before,
.nav-list li li.highlight.active > a:before {
  display: none;
}
.nav-list > li.highlight.active > a:after,
.nav-list > li.highlight.active > a:before {
  display: block;
}
.no-skin .nav-list > li.highlight:hover,
.no-skin .nav-list > li.highlight.active {
  border-color: #c8d8e2;
}
.no-skin .nav-list > li.highlight:hover + li,
.no-skin .nav-list > li.highlight.active + li {
  border-top-color: #c8d8e2;
}
.no-skin .nav-list > li.highlight:hover > a,
.no-skin .nav-list > li.highlight.active > a {
  background-color: #FFF;
}
.no-skin .nav-list > li.highlight.active:after {
  display: none;
}
.no-skin .nav-list > li.highlight.active:before {
  display: block;
  background-color: #4f90c2;
}
.no-skin .nav-list > li.highlight.active > a {
  background-color: #f2f6f9 !important;
}
.no-skin .nav-list > li.highlight.active > a:before,
.no-skin .nav-list > li.highlight.active > a:after {
  display: block;
  content: "";
  position: absolute;
  top: -1px;
  right: -10px;
  bottom: auto;
  z-index: 1;
  border-style: solid;
  border-width: 20px 0 21px 10px;
  border-color: transparent;
}
.no-skin .nav-list > li.highlight.active > a:before {
  border-left-color: #a0bccd;
  -moz-border-left-colors: #a0bccd;
  right: -11px;
}
.no-skin .nav-list > li.highlight.active > a:after {
  border-left-color: #f2f6f9;
  -moz-border-left-colors: #f2f6f9;
}
.no-skin .nav-list li li.highlight.active > a {
  background-color: #f2f6f9;
}
.no-skin .nav-list li li.highlight.active.open > a {
  background-color: #FFF;
}
.no-skin .nav-list li li.highlight.active.open > a:hover {
  background-color: #f1f5f9;
}
@media (min-width: 992px) {
  .no-skin .sidebar.h-sidebar .nav-list > li.highlight.active > a:after {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: auto;
    right: auto;
    bottom: -2px;
    left: 50%;
    margin-left: -7px;
    border-color: transparent;
    -moz-border-right-colors: none;
    -moz-border-left-colors: none;
    -moz-border-top-colors: none;
    border-width: 8px 7px;
    border-bottom-color: #FFF;
    -moz-border-bottom-colors: #FFF;
  }
  .no-skin .sidebar.h-sidebar .nav-list > li.highlight.active > a:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-left: -7px;
    border-width: 8px;
    border-color: transparent;
    -moz-border-right-colors: none;
    -moz-border-left-colors: none;
    -moz-border-top-colors: none;
    border-bottom-color: #74add7;
    -moz-border-bottom-colors: #74add7;
  }
  .no-skin .sidebar.h-sidebar .nav-list > li.highlight.active:hover > a:after,
  .no-skin .sidebar.h-sidebar .nav-list > li.highlight.active.hover-show > a:after,
  .no-skin .sidebar.h-sidebar .nav-list > li.highlight.active:hover > a:before,
  .no-skin .sidebar.h-sidebar .nav-list > li.highlight.active.hover-show > a:before {
    display: none;
  }
}
@media (min-width: 992px) {
  .sidebar.compact .nav-list li.highlight.active > a:after,
  .sidebar.compact .nav-list li.highlight.active > a:before {
    display: none;
  }
  .sidebar.compact.menu-min .nav-list li.highlight.active > a:after,
  .sidebar.compact.menu-min .nav-list li.highlight.active > a:before {
    display: block;
  }
}
@media (min-width: 992px) {
  .sidebar.h-sidebar .nav-list li.highlight.active > a:after,
  .sidebar.h-sidebar .nav-list li.highlight.active > a:before {
    display: block;
    border-width: 8px !important;
    -moz-border-left-colors: none !important;
    -moz-border-right-colors: none !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
  }
  .sidebar.h-sidebar .nav-list > li.highlight.active:hover > a:after,
  .sidebar.h-sidebar .nav-list > li.highlight.active:hover > a:before {
    display: none !important;
  }
}
.sidebar-scroll .nav-list > li.active.highlight > a:after,
.sidebar-scroll .nav-list > li.active.highlight > a:before {
  display: none !important;
}
.onpage-help-backdrop {
  position: absolute;
  z-index: 99990;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  filter: alpha(opacity=5);
  background-color: rgba(0, 0, 0, 0.05);
}
.onpage-help-section {
  display: block;
  position: absolute;
  z-index: 100000;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4DFFFFFF', endColorstr='#4DFFFFFF', GradientType=0);
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px dashed #8BBCD3;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s;
  -webkit-transition: background-color 0.2s, border-color 0.2s;
  text-align: center;
  vertical-align: middle;
  outline: none !important;
}
.onpage-help-section > .ie-hover-fix {
  /* ie8-9 fix*/
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  filter: alpha(opacity=1);
}
.onpage-help-section:focus,
.onpage-help-section:active {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#33C9D8EA', endColorstr='#33C9D8EA', GradientType=0);
  background-color: rgba(201, 216, 234, 0.2);
  border-color: #77ACC4;
}
.onpage-help-section:hover {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66C9D8EA', endColorstr='#66C9D8EA', GradientType=0);
  background-color: rgba(201, 216, 234, 0.4);
  border-color: #77ACC4;
  border-style: solid;
}
.onpage-help-section > .help-icon-1 {
  font-size: 18px;
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  opacity: 0;
  filter: alpha(opacity=0);
  color: #FFF;
  text-shadow: 0 0 2px black;
  background-color: #85B8DB;
  border: 2px solid #FFF;
  border-radius: 100%;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
  width: 48px;
  height: 48px;
  line-height: 46px;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
}
.onpage-help-section:focus > .help-icon-1,
.onpage-help-section:active > .help-icon-1 {
  opacity: 0.5;
}
.onpage-help-section:hover > .help-icon-1 {
  opacity: 1;
  filter: alpha(opacity=100);
}
.onpage-help-section.help-section-small > .help-icon-1 {
  font-size: 18px;
  width: 28px;
  height: 28px;
  line-height: 26px;
  border-radius: 12px;
}
.onpage-help-section.help-section-smaller > .help-icon-1 {
  font-size: 14px;
  width: 20px;
  height: 20px;
  line-height: 17px;
  border-radius: 8px;
}
.onpage-help-section > .help-icon-2 {
  position: absolute;
  z-index: 2;
  left: -4px;
  top: -4px;
  width: 18px;
  height: 18px;
  line-height: 16px;
  display: block;
  font-size: 16px;
  color: orange;
  background-color: #FFF;
  border: 1px solid orange;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.onpage-help-section:hover > .help-icon-2 {
  color: #59A34E;
  border-color: #59A34E;
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
}
.onpage-help-modal {
  z-index: 100010;
}
.onpage-help-modal + .modal-backdrop {
  z-index: 100009;
}
.onpage-help-modal-buttons button {
  border-radius: 100%;
  border-width: 2px !important;
}
.onpage-help-modal-buttons button:focus {
  outline: none !important;
}
.onpage-help-modal-buttons button.disabled {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
}
.onpage-help-content {
  max-width: 800px;
  margin: 0 auto;
}
.code-modal.onpage-help-modal .modal-dialog {
  margin-top: 6px;
  margin-bottom: 6px;
}
.code-modal.onpage-help-modal .modal-header {
  padding-top: 6px;
  padding-bottom: 6px;
}
.code-modal.onpage-help-modal .modal-body {
  padding: 10px 12px;
}
.onpage-help-modal pre {
  border-width: 0;
  box-shadow: none;
  border-radius: 0;
  margin-top: 8px;
}
.onpage-help-modal .modal-title code {
  font-size: 13px;
}
.onpage-help-modal .modal-body {
  font-size: 14px;
}
@media print {
  .navbar {
    display: none !important;
    /**
	background: transparent none !important;
	border-bottom: 1px solid #DDD;
	
	.navbar-brand {
		color: @text-color !important;
	}

	.ace-nav {
		display: none !important;
	}
	*/
  }
  .sidebar {
    display: none !important;
  }
  .main-content {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .main-content .nav-search {
    display: none !important;
  }
  .main-content .breadcrumbs {
    float: right;
    border-width: 0 !important;
  }
  .main-content .breadcrumbs .breadcrumb > li + li:before {
    content: "/";
  }
  .main-content .breadcrumbs .breadcrumb .home-icon {
    display: none;
  }
  .ace-settings-container {
    display: none !important;
  }
  .footer {
    width: 100%;
    height: auto;
    position: relative;
  }
  .footer .footer-inner,
  .footer .footer-inner .footer-content {
    width: 100%;
  }
  .btn-scroll-up {
    display: none !important;
  }
  .btn,
  .btn.btn-app {
    background: transparent none !important;
    border-width: 1px !important;
    border-radius: 0 !important;
    color: #555 !important;
  }
  .label {
    border: 1px solid #666 !important;
  }
  .label[class*=arrowed]:before,
  .label[class*=arrowed]:after {
    display: none !important;
  }
}
