#popup_container {
	font-family: Verdana, Aerial;
	font-size: 1.2em;
	min-width: 300px; /* Dialog will be no smaller than this */
	max-width: 580px; /* Dialog will wrap after this width */
	background: #FFF;
	border: solid 5px #999;
	color: #000;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

#popup_title {
	font-size: 1.3em;
	font-weight: bold;
	text-align: center;
	line-height: 1.75em;
	color: #666;
	background: #CCC url(img/title.gif) top repeat-x;
	border: solid 1px #FFF;
	border-bottom: solid 1px #999;
	cursor: default;
	padding: 0em;
	margin: 0em;
}

#popup_content {
	background: 16px 16px no-repeat url(img/info.gif);
	padding: 1em 1.75em;
	margin: 0em;
}

#popup_content.alert {
	background-image: url(img/info.gif);
}

#popup_content.confirm {
	background-image: url(img/important.gif);
}

#popup_content.prompt {
	background-image: url(img/help.gif);
}

#popup_message {
	padding-left: 48px;
}

#popup_panel {
	text-align: center;
	margin: 1em 0em 0em 1em;
}

#popup_prompt {
	margin: .5em 0em;
}