/** overwrite element defined by ace **/
.page-content {
	background-color: #fcfcfc;
}
label{
	font-size: 13px;
}

.map-well-sm{
	padding: 9px;
	border-radius: 3px;
	margin-bottom: 5px;
}
.map-spacer1{ height: 1px; }
.map-spacer2{ height: 2px; }
.map-spacer3{ height: 3px; }
.map-spacer4{ height: 4px; }
.map-spacer5{ height: 5px; }
.map-spacer10{ height: 10px; }
.map-spacer15{ height: 15px; }

/* multiple select width */
.w10{ width: 10px !important; }
.w20{ width: 20px !important; }
.w50{ width: 50px !important; }
.w80{ width: 80px !important; max-width: 80px !important; }
.w100{ width: 100px !important; max-width: 100px !important; }
.w110{ width: 110px !important; max-width: 110px !important; }
.w120{ width: 120px !important; max-width: 120px !important; }
.w150{ width: 150px !important; max-width: 150px !important; }
.w200{ width: 200px !important; max-width: 200px !important; }
.w250{ width: 250px !important; max-width: 250px !important; }
.w300{ width: 300px !important; max-width: 300px !important; }

/*  panel item  */
.panel-first{
	padding-left: 12px;
	padding-right: 2px;
}
.panel-next{
	padding-left: 0px;
	padding-right: 2px;
}

@media only screen and (max-width: 360px) {
	.panel-first, .panel-next{
		padding-left: 12px;
		padding-right: 12px;
	}
}


/*  dataTable class */
.dataTables_wrapper{
    border-top: 1px solid #DDD;
}
.dataTableFormat > tr > td{
	/*
    padding: 0px !important;
    margin: 0px !important;
    */
}
.dataTableFormat > tr > td .text-label{
	padding-top: 8px !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
	padding-bottom: 8px !important;
}
.dataTables_wrapper input[type=text].search-input-text{
	width: 100%;
	margin: 1px;
}

.mapTable > tbody > tr > td.dt-field {
	padding: 0px !important;
}
.mapTable > tbody > tr > td .text-label{
	padding: 6px 10px 6px 10px !important;
}
.mapTable > tbody > tr > td > input[type=text]{
	margin: 0px !important;
}
.dt-label{
	padding: 6px 10px !important;
}

/*  header class */
.header{
    margin-bottom: 5px;
}

/*  fonts   */
.font12{
    font-size: 12px !important;
}

/*  btn dropdown toggle */
.map-btn-dropdown{
    padding: 6px 9px;
}

/*  padding */
.no-padding-left{ padding-left: 0px !important; }
.padding-left-1{ padding-left: 1px !important; }
.padding-left-2{ padding-left: 2px !important; }
.padding-left-5{ padding-left: 5px !important; }
.padding-left-10{ padding-left: 10px !important; }
.padding-left-15{ padding-left: 15px !important; }
.padding-left-20{ padding-left: 20px !important; }
.padding-left-30{ padding-left: 30px !important; }
.padding-right-1{ padding-right: 1px !important; }
.padding-right-2{ padding-right: 2px !important; }
.padding-right-5{ padding-right: 5px !important; }
.padding-right-10{ padding-right: 10px !important; }
.padding-1 { padding: 1px !important; }
.padding-2 { padding: 2px !important; }
.padding-3 { padding: 3px !important; }
.padding-5 { padding: 5px !important; }
.padding-bottom-5 { padding-bottom: 5px !important; }
.padding-bottom-8 { padding-bottom: 8px !important; }
.padding-bottom-10 { padding-bottom: 10px !important; }

/*  margin */
.no-margin-left{ margin-left: 0px !important; }
.margin-left-5{ margin-left: 5px !important; }
.margin-left-6{ margin-left: 6px !important; }
.margin-left-10{ margin-left: 10px !important; }
.margin-left-15{ margin-left: 15px !important; }
.margin-left-20{ margin-left: 20px !important; }
.margin-left-30{ margin-left: 30px !important; }
.no-margin-right{ margin-right: 0px !important; }
.margin-right-5{ margin-right: 5px !important; }
.margin-right-6{ margin-right: 6px !important; }
.margin-right-10{ margin-right: 10px !important; }
.margin-right-15{ margin-right: 15px !important; }
.margin-right-20{ margin-right: 20px !important; }
.margin-right-30{ margin-right: 30px !important; }
.margin-top-0 { margin-top: 0px !important; }
.margin-top-1 { margin-top: 1px !important; }
.margin-top-2 { margin-top: 2px !important; }
.margin-top-3 { margin-top: 3px !important; }
.margin-top-4 { margin-top: 4px !important; }
.margin-top-5 { margin-top: 5px !important; }
.margin-top-10 { margin-top: 10px !important; }
.margin-top-20 { margin-top: 20px !important; }
.margin-top-30 { margin-top: 30px !important; }
.margin-top-40 { margin-top: 40px !important; }
.margin-top-50 { margin-top: 50px !important; }
.margin-bottom-0 { margin-bottom: 0px !important; }
.margin-bottom-5 { margin-bottom: 5px !important; }
.margin-bottom-15 { margin-bottom: 15px !important; }


/*  text vertical alignment */
.text-vtop{ vertical-align: text-top !important; }
.text-vcenter{ vertical-align: middle !important; }
.text-vbottom{ vertical-align: text-bottom !important; }


/*  dataTable settings */
/*
temporary taken out from testing.
.table > tbody > tr.odd.selected { background-color: blue; }
.table > tbody > tr.even.selected { background-color: blue; }
*/


/* fix .input-group width issue */
   .select2-container--default .select2-search--inline .select2-search__field {
	   width: auto !important;
   }




/*  overwrite ace */
.form-group > label[class*="col-"] {
	margin-bottom: 0px;
}


/*  modal */
@media screen and (min-width: 768px) {

	#itemModal .modal-dialog  { width:90%; height: 90%; }

}


.map-lbl{
	/*
	padding-top: 7px !important;
	padding-bottom: 7px !important;
	*/
	padding-right: 0px !important;
}

/*  section */
@media only screen and (min-width: 650px) {
	.map-section {
		margin-top: 10px;
		display: flex;
		border: 1px solid #ccc;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		background-color: #EEEEEE;
		margin-bottom: 20px;
	}

	.map-section > div.map-content_description {
		padding: 20px;
		flex: 1 1 25%;
		max-width: 25%;
	}

	.map-section > div.map-content_main {
		padding: 20px 30px 20px 30px;
		flex: 1 1 0%;
		background-color: #FFFFFF;
		border-left: 1px solid #ccc;
		-webkit-border-top-right-radius: 5px;
		-moz-border-radius-topright: 5px;
		border-top-right-radius: 5px;
		-webkit-border-bottom-right-radius: 5px;
		-moz-border-radius-bottomright: 5px;
		border-bottom-right-radius: 5px;
	}
}
@media only screen and (max-width: 650px) {
	.map-section{
		margin-top: 10px;
		border: 1px solid #ccc;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		background-color: #EEEEEE;
		margin-bottom: 20px;
	}

	.map-section > div.map-content_description{
		padding: 20px;
	}
	.map-section > div.map-content_main{
		padding: 20px 30px 20px 30px;
		background-color: #FFFFFF;
		border-top: 1px solid #ccc;
		-webkit-border-bottom-left-radius: 5px;
		-moz-border-radius-bottomleft: 5px;
		border-bottom-left-radius: 5px;
		-webkit-border-bottom-right-radius: 5px;
		-moz-border-radius-bottomright: 5px;
		border-bottom-right-radius: 5px;
	}
}


/*  bg color */
.bg-grey{ background-color: #FAFAFA; }
.bg-light-blue{ background-color: #d9edf7; }

.bold{
	font-weight: bold;
}

/*  widget */
.widget-continue{
	padding-left: 12px;
	padding-right: 12px;
	margin-bottom: 10px;
}
.widget-last {
	padding-left: 12px;
	padding-right: 12px;
	margin-bottom: 2px;
}
@media only screen and (max-width: 768px) {
	.control-label {
		padding-top: 7px;
	}
	.form-group > .control-label{
		padding-top: 0px;
	}
}


/*  buttons */
.btn-corner{
	border-radius: 3px;
}

/*  cursor */
.cursor-pointer{
	cursor: pointer;
}

/*  add autocomplete loading icon */
.ui-autocomplete-loading {
	background:url('/assets/css/images/loading.gif') no-repeat right center;
}



/* dropdown-menu from ace.css */
.dropdown-light > li.danger {

}
.dropdown-light > li.danger a:hover{
	background: #D3413B !important;
	color: #FFFFFF;
}



.rounded {
	border-radius: 4px !important;
}

@media only screen and (max-width: 480px) {
	.btn > .ace-icon{
		margin-right: 0px;
	}
}


/*Rounded and Circle Classes
------------------------------------*/
.no-rounded { border-radius: 0 !important;}
.rounded { border-radius: 4px !important;}
.rounded-x { border-radius: 50% !important;}
.rounded-2x { border-radius: 10px !important;}
.rounded-3x { border-radius: 15px !important;}
.rounded-4x { border-radius: 20px !important;}
.rounded-sm { border-radius: 2px !important;}
.rounded-md { border-radius: 3px !important;}
.rounded-top { border-radius: 4px 4px 0 0 !important;}
.rounded-left { border-radius: 4px 0 0 4px !important;}
.rounded-right { border-radius: 0 4px 4px 0 !important;}
.rounded-bottom { border-radius: 0 0 4px 4px !important;}



.border-1{
	border: 1px solid #ccc;
	padding: 2px;
}


/** plugins **/
.ui-autocomplete { z-index:1050 !important;}
.datepicker{ z-index:1050 !important; }